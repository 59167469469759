import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./style.css";
import { useEffect, useState } from "react";
import getList from "../../axios/getList";
import styled from "styled-components";
import Popup from "../Atoms/popup";
import OtherPopup from "../Atoms/otherPopup";
const MainSlider = () => {
  const [slideList, setSlideList] = useState([]);
  useEffect(() => {
    getList("/slide/list").then((data) => {
      const list = [];
      for (let i = 0; i < data.length; i++) {
        list.push(
          <SlickItem
            url={data[i].imgUrl}
            key={"slide" + i}
            className="slick-item"
          >
            <div>
              <h3>{data[i].content}</h3>
              <h2>{data[i].title}</h2>
            </div>
          </SlickItem>
        );
      }
      setSlideList(list);
    });
  }, []);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <>
      <Popup />
      <OtherPopup />
      <Slider className="main-slider" {...settings}>
        {slideList}
      </Slider>
    </>
  );
};
const SlickItem = styled.div`
  background-image: linear-gradient(
      0deg,
      rgba(55, 53, 42, 0.38) 0%,
      rgba(150, 141, 105, 0) 40%
    ),
    url("${(props) => props.url}");
`;

export default MainSlider;
