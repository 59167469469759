import { useEffect } from "react"
import getList from "../../axios/getList"
import Table from "../Atoms/table"
import { useState } from "react"
import postItem, { loginPost } from "../../axios/post"

const AdminSetting = ()=>{
    const [row, setRow] = useState([])
    const [add,setAdd] = useState({id:null,pass:null,pass2:null})
    const [change,setChange] = useState({id:"선택되지 않음",pass:null,pass2:null})
    const [login,setLogin] = useState({id:null,pass:null})
    
    useEffect(() => {
        getList("/user/list").then(async data => {
            for(let i = 0; i<data.length ; i++){
                data[i]["update"] = <button className="button" onClick={()=>setChange({...change,id:data[i].userId})}>패스워드 변경</button>
                data[i]["delete"] =<>
                <div style={{width : "100%",display:"flex",alignItems:"center",justifyContent:"flex-start"}}>
                <button className="button"  onClick={()=>postItem("/user/delete",{id:data[i].userId})}>관리자 삭제</button>
                </div></> 
            }
            setRow(data)
        })
    }, [])
    const depositHeaderList =[
        {type:"number",data:"No", columnName:"id"},
        {type:"text",data:"id", columnName:"userId"}, 
        {type:"setting",data:"패스워드 변경", columnName:"update"},
        {type:"setting",data:"관리자 삭제", columnName:"delete"},
    ]

    return (
        < div className="reservation-check" style={{padding : "0 10%"}}>
            {window.sessionStorage.getItem("jwt")?<>
            <h1>관리자 설정</h1>
            <div className="admin-form add">
            <h2>새로운 관리자 추가</h2>
            <div className="form-item">
            <label>id </label><input onChange={(data)=>{setAdd({...add,id:data.target.value})}}></input>
            </div>
            <div className="form-item">
            <label>password </label><input onChange={(data)=>{setAdd({...add,pass:data.target.value})}}></input>
            </div>
            <div className="form-item">

            <label>password check </label><input onChange={(data)=>{setAdd({...add,pass2:data.target.value})}}></input>
            </div>
            <button onClick={()=>{postItem("/user/insert",{...add});}} style={{width : "100%"}} className="button">추가하기</button>
            </div>
            <div className="admin-form change">
            <h2>관리자 패스워드 변경</h2>
            <div className="form-item">
            <label>id</label>{change.id}
            </div>
            <div className="form-item">
            <label>password </label><input onChange={(data)=>{setChange({...change,pass:data.target.value})}}></input>
            </div>
            <div className="form-item">
            <label>password check </label><input onChange={(data)=>{setChange({...change,pass2:data.target.value})}}></input>
            </div>
            <button onClick={()=>{postItem("/user/update-pass",{...change});}} style={{width : "100%"}} className="button">수정하기</button>
            </div>
            <h2 style={{color : "red",marginBottom:"30px"}}> ** 비밀번호 패턴은 8자 이상 / 특수문자 / 영어 / 숫자 조합입니다. ** </h2>

            <Table
            headerList={depositHeaderList}
            rowList={row}
            ></Table></>:<div className="login">
            <h1>로그인</h1>
            <div className="form-item">
            <label>id </label><input onChange={(data)=>{setLogin({...login,id:data.target.value})}}></input>
            </div>
            <div className="form-item">
            <label>password </label><input onChange={(data)=>{setLogin({...login,pw:data.target.value})}}></input>
            </div>
            <button onClick={()=>{loginPost({...login}).then(data=>{window.sessionStorage.setItem("jwt",data);window.location.reload()})}} style={{width : "100%"}} className="button">로그인</button>
            </div>

            }

        </div>
    )
}
export default AdminSetting;