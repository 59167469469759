import { useEffect } from "react"
import getList from "../../axios/getList"
import Table from "../Atoms/table"
import ReservationAdmin from "./reservationAdmin"
import { useState } from "react"
import ReservationAdminAll from "./reservationAdminAll"

const ReservationSetting = ()=>{
    const [row, setRow] = useState([])

    useEffect(() => {
        if(!window.sessionStorage.getItem("jwt")){
            alert("로그인이 필요합니다!")
            window.location.href="/admin"
        }
        getList("/camp/list").then(async data => {
            for(let i = 0; i<data.length ; i++){
                data[i]["setting"] = <>        
                 <h1>{data[i]["siteName"]}</h1>
                <ReservationAdmin idx={data[i].id} flag={true.toString()+data[i].id}/>
                <ReservationAdmin idx={data[i].id} flag={false.toString()+data[i].id}/>
                <ReservationAdmin idx={data[i].id} flag={"money"+data[i].id}/>
                <ReservationAdmin idx={data[i].id} flag={"reservationStart"+data[i].id}/>
                <ReservationAdmin idx={data[i].id} flag={"reservationFalseDate"+data[i].id}/>

        </>
            }
            setRow(data)
        })
    }, [])
    const depositHeaderList =[
        {type:"setting",data:"설정창", columnName:"setting"},
    ]

    return (
        < div className="reservation-setting" style={{padding : "0 10%", height: "calc(100vh - 164px)" }}>
            <h1>각 사이트 별 예약 설정</h1>

            <Table 
            headerList={depositHeaderList}
            rowList={row}
            >            
            <ReservationAdminAll idx={1} flag={"reservationStartAll"}/>
            </Table>
        </div>
    )
}
export default ReservationSetting;