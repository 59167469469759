import Table from "../Atoms/table";
import PageNav from "../Atoms/pageNav";
import "./style.css"
import { useEffect, useState } from "react";
import getList from "../../axios/getList";

const Customer = ()=>{
    const [per, setPer] = useState(10);
    const [total,setTotal] = useState(10);
    const [pageMax,setPageMax] = useState(5);
    const [currentPage, setCurrentPage ] = useState(1);
    const [list, setList] = useState([]);
    const depositHeaderList =[
        {type:"number",data:"No", columnName:"id"},
        {type:"link",data:"제목", columnName:"title"}, 
        {type:"number",data:"조회수", columnName:"view"}, 
        {type:"date",data:"작성시간", columnName:"date"}, 

    ]
    useEffect(()=>{
        getList("/notice/total").then(data=>{
            setTotal(data)
            setPageMax(((data/per)+1).toFixed(0))
        })
        getList("/notice/list",currentPage-1,per).then(data=>{
            setList(data)
        })
    },[currentPage])
    return (
        <div className="info-div customer">
            <div className="info-title"> 공지사항</div>
            <Table
            headerList={depositHeaderList}
            rowList={list}/>
            <PageNav setPer={setPer} total={total} pageMax={pageMax} currentPage={currentPage} setCurrentPage={setCurrentPage}/>
        </div>
    )
}

export default Customer;