import { useEffect, useState } from "react"
import insertFileAndParams from "../../axios/insertFile"
import getList from "../../axios/getList"
import styled from "styled-components"
import deleteItem from "../../axios/delete"
import updateFileAndParams, { setEditInfo } from "../../axios/updateFile"

const SlideAdmin = () => {
    const [info, setInfo] = useState({ title: null, content: null })
    const [slideList, setSlideList] = useState([])

    useEffect(() => {
        if (!window.sessionStorage.getItem("jwt")) {
            alert("로그인이 필요합니다!")
            window.location.href = "/admin"
        }
        setEditInfo("content", null);
        setEditInfo("title", null);
        getList("/slide/list").then(data => {
            console.log(data)
            const list = []
            for (let i = 0; i < data.length; i++) {
                list.push(
                    <div key={"slide" + i}>
                        <SlickItem url={data[i].imgUrl} className="slick-item">
                            <div>
                                <h3>{data[i].content}</h3>
                                <input onBlur={(data) => { setEditInfo("content", data.target.value) }}></input>
                                <h2>{data[i].title}</h2>
                                <input onBlur={(data) => { setEditInfo("title", data.target.value) }}></input>
                            </div>
                            배경화면 수정 <input type='file' id={"file-background-" + data[i].id} name='uploadFile'></input>
                        </SlickItem>
                        <button onClick={() => { updateFileAndParams("file-background-" + data[i].id, "/slide/update", data[i].id) }}>위 내용으로 수정하기</button><br />

                        <button onClick={() => { deleteItem("/slide/delete", data[i].id) }}>아예 삭제하기</button>
                    </div>
                )
            }
            setSlideList(list)
        })
    }, [])
    return (
        <div>
            <p>새로운 요소 추가</p>
            <input type='file' id="file" name='uploadFile'></input>
            <input name='title' onBlur={(data) => { setInfo({ ...info, title: data.target.value }) }}></input>
            <input name='content' onBlur={(data) => { setInfo({ ...info, content: data.target.value }) }}></input>
            <button onClick={() => {
                insertFileAndParams("file", info, "/slide/insert");


            }}>add Slide</button>
            <div>
                {slideList}
            </div>
        </div>
    )
}
const SlickItem = styled.div`
background-image: linear-gradient(0deg, rgba(55, 53, 42, 0.38) 0%, rgba(150, 141, 105, 0) 40%),url("${props => props.url}");
`
export default SlideAdmin;