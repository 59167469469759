import { useEffect, useState } from "react"
import insertFileAndParams from "../../axios/insertFile"
import updateFileAndParams, { setEditInfo } from "../../axios/updateFile"
import getList from "../../axios/getList"
import deleteItem from "../../axios/delete"
import styled from "styled-components"
import postItem, { setEditInfoPost } from "../../axios/post"

const MainImgAdmin = () => {
    const [info, setInfo] = useState({ title: null, content: null })
    const [mainImgList, setMainImgList] = useState([])
    const [mainList, setMainList] = useState([])
    useEffect(() => {
        if (!window.sessionStorage.getItem("jwt")) {
            alert("로그인이 필요합니다!")
            window.location.href = "/admin"
        }
        setEditInfo("content", "");
        setEditInfo("title", "");
        setEditInfo("subTitle", "");
        getList("/main-img/main").then(data => {
            const list = []
            for (let i = 0; i < data.length; i++) {
                list.push(
                    <div key={data[i].id + "main"} className="info-title">
                        <h3>{data[i].subTitle}</h3>
                        <input onBlur={(data) => { setEditInfoPost("subTitle", data.target.value) }}></input>
                        <h1>{data[i].title}</h1>
                        <input onBlur={(data) => { setEditInfoPost("title", data.target.value) }}></input>
                        <p>{data[i].info}
                            <textarea onBlur={(data) => { setEditInfoPost("content", data.target.value) }}></textarea>
                        </p>
                        <button onClick={() => { postItem("/main-img/update-main", null);}}>위 내용으로 수정하기</button><br />
                    </div>
                )
            }
            //setMainList(list)
        })
        getList("/main-img/list").then(data => {
            const list = []
            for (let i = 0; i < data.length; i++) {
                list.push(
                    <div className={"info-item " + (i % 2 == 0 ? "left" : "right")} key={"slide" + i}>
                        <Img url={data[i].imgUrl} className="img"></Img>
                        <div className="content">
                            <h3>{data[i].subTitle}</h3>
                            <input placeholder="subTitle" style={{ width: "100%", margin: "10px 0 " }} onBlur={(data) => { setEditInfo("subTitle", data.target.value) }}></input>
                            <h1>{data[i].title}</h1>
                            <input placeholder="title" style={{ width: "100%", margin: "10px 0 " }} onBlur={(data) => { setEditInfo("title", data.target.value) }}></input>
                            <p>{data[i].info.split("\n").map(data=><>{data}<br/></>)}
                                <textarea placeholder="info" style={{ width: "100%", margin: "10px 0 " }} onBlur={(data) => { setEditInfo("content", data.target.value) }}></textarea>
                            </p>
                            <span style={{ width: "100%", padding: "10px 0", paddingBottom: "0", display: "block" }}>배경화면 수정</span><input style={{ width: "100%", margin: "10px 0" }} type='file' id={"file-background-" + data[i].id} name='uploadFile'></input>
                            <button className="button" style={{ width: "100%", margin: "10px 0" }} onClick={() => { updateFileAndParams("file-background-" + data[i].id, "/main-img/update", data[i].id)}}>위 내용으로 수정하기</button>
                            <button className="button" style={{ width: "100%", margin: "10px 0 " }} onClick={() => { deleteItem("/main-img/delete", data[i].id)}}>아예 삭제하기</button>
                        </div>
                    </div>
                )
            }
            setMainImgList(list)
        })
    }, [])

    return (
        <div className="info-div admin" >
                <div className="content" style={{width : "50%",margin : "0 auto"}}>
                <h1  style={{width : "100%",textAlign:"center"}}>새로운 메인화면 요소 추가</h1>
                <div className="form-item"><label>title</label><input style={{width:"70%"}} name='title' onBlur={(data) => { setInfo({ ...info, title: data.target.value }) }}></input><br/></div>
                <div className="form-item"><label>subTitle</label><input style={{width:"70%"}} name='subTitle' onBlur={(data) => { setInfo({ ...info, subTitle: data.target.value }) }}></input><br/></div>
                <div className="form-item"><label>content</label><input style={{width:"70%"}} name='content' onBlur={(data) => { setInfo({ ...info, content: data.target.value }) }}></input><br/></div>
                <div className="form-item"><label>배경화면</label> <input style={{width:"70%"}} type='file' id="file" name='uploadFile'></input></div>
                <button style={{marginTop:"20px"}} className="button" onClick={() => { insertFileAndParams("file", info, "/main-img/insert")  }}>새로운 메인화면 요소 추가</button>
            </div>
            {mainList}
            {mainImgList}
        </div>
    )
}
const Img = styled.div`
background-image: url("${props => props.url}");
`
export default MainImgAdmin;