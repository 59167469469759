import { useEffect } from "react";
import { useState } from "react";
import Calendar from "react-calendar";
import postItem from "../../axios/post";
import getList from "../../axios/getList";
import Table from "../Atoms/table";
import deleteItem from "../../axios/delete";
import axios from "axios";

const ReservationAdminAll = (props) => {
  const [value, onChange] = useState(
    new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate()
    )
  );
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [reservation, setReservation] = useState([]);
  const [count, setCount] = useState(0);
  const [price, setPrice] = useState(0);
  const [allSiteIdx, setAllSiteIdx] = useState([]);
  const [beforeList, setBeforeList] = useState([]);
  const formatDay = (locale) => {
    const dateData = new Date(locale);
    return dateData.getDate();
  };
  const setDate = (date) => {
    if (count % 2 == 0) {
      setStartDate(date);
    } else {
      setEndDate(date);
    }
    setCount(count + 1);
  };
  useEffect(() => {
    const distance = (endDate.getTime() - startDate.getTime()) / 86400000;
    const reservationList = [];
    for (let j = 0; j < distance; j++) {
      reservationList.push(
        new Date(startDate.getTime() + 86400000 * j).getTime()
      );
    }
    reservationList.push(endDate.getTime());
    setReservation(reservationList);
    const calendarView = document.querySelectorAll(
      "." + props.flag + " .react-calendar__month-view__days"
    );
    const childrenList = calendarView[0].children;

    if (beforeList.length > 0) {
      for (let j = 0; j < beforeList.length; j++) {
        beforeList[j].style.background = "none";
        beforeList[j].style.color = "#313131";
        if (beforeList[j].children[0].innerHTML == new Date().getDate()) {
        }
      }
    }
    let i = 0;
    for (i = 0; i <= childrenList.length; i++) {
      if (childrenList[i]) {
        const value = childrenList[i].children[0].innerHTML;
        if (
          childrenList[i].children[0].ariaLabel.includes(
            startDate.getMonth() + 1 + "월"
          )
        ) {
          if (value == startDate.getDate()) {
            break;
          }
        }
      }
    }
    const start = i;
    const before = [];
    for (; i <= start + distance; i++) {
      if (childrenList[i]) {
        const value = childrenList[i];
        value.style.background = "#E9F2FF ";
        value.style.color = "#0066FF";
        before.push(value);
      }
    }
    setBeforeList(before);
  }, [endDate]);

  const [row, setRow] = useState([]);

  useEffect(() => {
    getList("/camp/list").then((data) => {
      const temp = [];
      data.map((data) => {
        temp.push(data.id);
      });
      setAllSiteIdx(temp);
    });

    getList(
      "/setting/setting-list?idx=" +
        props.idx +
        "&flag=" +
        (props.flag.toString().includes("true")
          ? "1"
          : props.flag.toString().includes("false")
          ? "0"
          : props.flag.toString().includes("money")
          ? "3"
          : props.flag.toString().includes("reservationStart")
          ? "4"
          : "5")
    ).then(async (data) => {
      for (let i = 0; i < data.length; i++) {
        data[i]["settingType"] =
          data[i]["settingType"] == "1"
            ? "비연박가능"
            : data[i]["settingType"] == "0"
            ? "연박만 가능"
            : data[i]["settingType"] == "3"
            ? "가격설정"
            : "예약금지시작일자";
        data[i]["delete"] = (
          <button
            className="button"
            onClick={() => {
              deleteItem("/setting/delete-setting", data[i].id);
            }}
          >
            설정 삭제
          </button>
        );
        data[i]["endDate"] = parseInt(
          data[i].dateList.split(",")[data[i].dateList.split(",").length - 1] >
            new Date(parseInt(data[i].startDate)).getTime()
            ? data[i].dateList.split(",")[
                data[i].dateList.split(",").length - 1
              ]
            : new Date(parseInt(data[i].startDate)).getTime()
        );
      }
      setRow(data);
    });
  }, []);

  return (
    <div
      className={props.flag.toString() + " setting-wrapper"}
      style={{ width: "100%", display: "flex", padding: "10px 0" }}
    >
      <div className="setting-wrapper-inputs" style={{ margin: "0 10px" }}>
        <Calendar
          onChange={setDate}
          value={value}
          formatDay={(locale, date) => formatDay(date, "d")}
        />
        {props.flag == "money" + props.idx ? (
          <input
            style={{ display: "block", width: "100%" }}
            onBlur={(data) => {
              setPrice(data.target.value);
            }}
          ></input>
        ) : null}
        <button
          style={{ display: "block", width: "100%" }}
          onClick={() => {
            allSiteIdx.map((data, index) => {
              axios
                .post(
                  "http://doorangicamping.com:8080/admin/setting/insert-setting",
                  {
                    idx: data,
                    dates: reservation[0]
                      ? reservation.toString()
                      : startDate.getTime(),
                    type: "reservationFalseDate",
                    startDate: startDate.toLocaleDateString(),
                    price: "0",
                  }
                )
                .then(() => {
                  if (index == allSiteIdx.length - 1) {
                    alert("모든 사이트 예약 금지일 설정 완료!");
                    window.location.reload();
                  }
                });
            });
          }}
        >
          {"모든 사이트 예약 금지일 정하기"}
        </button>
      </div>
    </div>
  );
};
export default ReservationAdminAll;
