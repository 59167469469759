import { useEffect } from "react";
import getList from "../../axios/getList";
import Table from "../Atoms/table";
import { useState } from "react";
import postItem from "../../axios/post";
import deleteItem from "../../axios/delete";

const ReservationCheckingAdmin = () => {
  const [row, setRow] = useState([]);

  useEffect(() => {
    if (!window.sessionStorage.getItem("jwt")) {
      alert("로그인이 필요합니다!");
      window.location.href = "/admin";
    }
    getList("/reservation/list-all").then(async (data) => {
      for (let i = 0; i < data.length; i++) {
        data[i]["endDate"] = data[i]["endDate"];
        data[i]["ok"] = (
          <button
            className="button"
            onClick={() => {
              postItem("/reservation/update", { idx: data[i].id });
            }}
          >
            승인
          </button>
        );
        data[i]["delete"] = (
          <button
            className="button"
            onClick={() => {
              deleteItem("/reservation/delete", data[i].id);
            }}
          >
            거부
          </button>
        );
        data[i]["okAdmin"] = (
          <button
            className="button"
            onClick={() => {
              deleteItem("/reservation/ok", data[i].id);
            }}
          >
            예약승인
          </button>
        );
      }
      setRow(data);
    });
  }, []);
  const depositHeaderList = [
    { type: "number", data: "예약 번호", columnName: "id" },
    { type: "text", data: "사이트 이름", columnName: "siteName" },
    { type: "date", data: "체크인", columnName: "startDate" },
    { type: "date", data: "체크 아웃", columnName: "endDate" },

    { type: "text", data: "입금자 이름", columnName: "guestName" },
    { type: "text", data: "휴대폰 번호", columnName: "phone" },
    { type: "text", data: "차량번호", columnName: "carNumber" },
    { type: "text", data: "인원수", columnName: "many" },

    { type: "text", data: "입금예정금액", columnName: "price" },
    { type: "state", data: "예약승인", columnName: "adminOk" },

    { type: "state", data: "상태", columnName: "state" },

    { type: "setting", data: "예약승인", columnName: "okAdmin" },
    { type: "setting", data: "입금완료", columnName: "ok" },
    { type: "setting", data: "예약 취소", columnName: "delete" },
  ];

  return (
    <div className="reservation-check" style={{ padding: "0 10%" }}>
      <h1>예약 승인 설정</h1>
      <Table headerList={depositHeaderList} rowList={row}></Table>
    </div>
  );
};
export default ReservationCheckingAdmin;
