import { useEffect, useState } from "react"
import postItem from "../../axios/post"
import getList from "../../axios/getList"
import deleteItem from "../../axios/delete"

const UseAdmin = ()=>{
    const [info, setInfo] = useState({ title: null, content: null })
        const [useList, setUseList] = useState([])
        useEffect(() => {
            if(!window.sessionStorage.getItem("jwt")){
                alert("로그인이 필요합니다!")
                window.location.href="/admin"
            }
        getList("/use/list").then(data => {
            const list = []
            for (let i = 0; i < data.length; i++) {
                const contentList = data[i].content.split("\n");
                const liList = [];
                for (let j = 0; j < contentList.length; j++) {
                    liList.push(<li key={"use-li-"+j}>{contentList[j]}</li>);
                }
                list.push(
                    <div key={"use" + i} className="item">
                        <div className="title">{data[i].title}</div>
                        <div className="content">
                            <ul>
                                {liList}
                            </ul>
                        </div>
                        <button className="button" onClick={()=>{deleteItem("/use/delete",data[i].id)}}>삭제하기</button>
                    </div>
                )
            }
            setUseList(list)
        })
    }, [])

    return (
        <div className="info-div use admin">
            <h1 >이용안내 관리</h1>
            <div className="use-div">
            {useList}
            </div>
            <div className="form add">
            <h1>새로운 이용안내 추가</h1>
            제목 <br/><input name='title' onBlur={(data) => { setInfo({ ...info, title: data.target.value }) }}></input>
            <br/>내용 <br/><textarea name='content' onBlur={(data) => { setInfo({ ...info, content: data.target.value }) }}></textarea>
            <button className="button"  onClick={() => {postItem("/use/insert",info);}}>이용안내 추가</button>

            </div>

        </div>
    )
}
export default UseAdmin;