import Table from "../Atoms/table";
import PageNav from "../Atoms/pageNav";
import "./style.css"
import { useEffect, useState } from "react";
import getList from "../../axios/getList";
import { Link, useParams } from "react-router-dom";
import Content from "../Atoms/content";

const CustomerContent = ()=>{
    const [per, setPer] = useState(10);
    const [total,setTotal] = useState(10);
    const [pageMax,setPageMax] = useState(5);
    const [currentPage, setCurrentPage ] = useState(1);
    const [list, setList] = useState([]);
    const { idx } = useParams();

    useEffect(()=>{
        getList("/notice/view?idx="+idx).then(data=>{
            setList(data)
        })
    },[currentPage])
    
    return (
        <div className="info-div customer">
            <div className="info-title"> 공지사항</div>
            <Content
            rowList={list}/>
            <br/>
            <Link className="link" to="/customer">{`>>`}돌아가기</Link>
        </div>
    )
}

export default CustomerContent;