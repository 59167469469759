import "./main/style.css"
import {useEffect} from "react"
import { Link } from "react-router-dom";
import * as React from "react";
import ham from "./img/ham.png"
const Header = ()=>{
    useEffect(()=>{
        if(window.innerWidth<=750){
          const element = document.getElementById("mo-menu")
          element.style.display = "none"

          }
    },[])
    const hamButton = () => {
        const element = document.getElementById("mo-menu")
        if (element.style.display == "none") {
          element.style.display = "flex"
        } else {
          element.style.display = "none"
        }
      }
    return (
        <>
    <div className="header">
      <div id="header-logo">
      <Link to="/" >두랭이해변오토캠핑장</Link>
      </div>
      <ul id="mo-menu">
        <li onClick={()=>{        if(window.innerWidth<=750){
          const element = document.getElementById("mo-menu")
          element.style.display = "none"

          }
}}><Link to="/list" >캠핑장 안내</Link></li>
        <li onClick={()=>{        if(window.innerWidth<=750){
          const element = document.getElementById("mo-menu")
          element.style.display = "none"

          }
}}><Link to="/reservation-current" >캠핑장 예약현황</Link></li>
        <li onClick={()=>{        if(window.innerWidth<=750){
          const element = document.getElementById("mo-menu")
          element.style.display = "none"

          }
}}><Link to="/use-info" >이용안내</Link></li>
        <li onClick={()=>{        if(window.innerWidth<=750){
          const element = document.getElementById("mo-menu")
          element.style.display = "none"

          }
}}><Link to="/customer" >공지사항</Link></li>
        <li onClick={()=>{        if(window.innerWidth<=750){
          const element = document.getElementById("mo-menu")
          element.style.display = "none"

          }
}}><Link to="/list" >예약하기</Link></li>
      </ul>
      <div id="ham-button" onClick={()=>hamButton()}>
        <img src={ham} />
      </div>
    </div>
</>
    )
}
export default Header;