import axios from "axios";

let editInfo = {};
const updateFileAndParams = (fileId, url, idx) => {
  let fileInput = document.getElementById(fileId);
  let fileObj = fileInput.files[0];
  let formData = new FormData();
  formData.append("uploadFile", fileObj);
  const paramKeys = Object.keys(editInfo);
  for (let i = 0; i < paramKeys.length; i++) {
    formData.append(paramKeys[i], editInfo[paramKeys[i]]);
  }
  formData.append("idx", idx);
  return axios
    .post("http://doorangicamping.com:8080/admin" + url, formData)
    .then((data) => {
      window.location.reload();
    });
};
export const setEditInfo = (key, value) => {
  editInfo[key] = value;
};
export default updateFileAndParams;
