import { useEffect, useState } from "react";
import postItem from "../../axios/post";
import getList from "../../axios/getList";
import Table from "../Atoms/table";
import PageNav from "../Atoms/pageNav";
import deleteItem from "../../axios/delete";
import { setEditInfoPost } from "../../axios/post";

const PopupAdmin = () => {
  const [info, setInfo] = useState({ font: 0, message: null });
  const [per, setPer] = useState(10);
  const [total, setTotal] = useState(10);
  const [pageMax, setPageMax] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [list, setList] = useState([]);
  const depositHeaderList = [
    { type: "number", data: "No", columnName: "id" },
    { type: "setting", data: "수정", columnName: "edit" },
  ];
  useEffect(() => {
    if (!window.sessionStorage.getItem("jwt")) {
      alert("로그인이 필요합니다!");
      window.location.href = "/admin";
    }
    getList("/popup/total").then((data) => {
      setTotal(10);
      setPageMax((10 / per + 1).toFixed(0));
      // TODO :: 수정 필요
    });
    getList("/popup/list", currentPage - 1, per).then((data) => {
      setEditInfoPost("title", null);
      setEditInfoPost("message", null);
      for (let i = 0; i < data.length; i++) {
        data[i].edit = (
          <div className="form edit">
            내용
            <br />{" "}
            <textarea
              name="message"
              onBlur={(data) => {
                setEditInfoPost("message", data.target.value);
              }}
            ></textarea>
            <br />
            <button
              style={{
                width: "49%",
                margin: 0,
                marginRight: "2%",
                display: "inline-block",
              }}
              className="button"
              onClick={() => {
                setEditInfoPost("idx", data[i].id);
                postItem("/popup/update", null);
              }}
            >
              수정
            </button>
            <button
              style={{ width: "49%", margin: 0, display: "inline-block" }}
              className="button"
              onClick={() => {
                deleteItem("/popup/delete", data[i].id);
              }}
            >
              삭제
            </button>
          </div>
        );
      }
      setList(data);
    });
  }, [total, currentPage]);
  return (
    <div>
      <div className="admin notice">
        <div className="admin notice wrapper">
          <div className="form add">
            <h1>새로운 팝업</h1>
            내용{" "}
            <textarea
              name="message"
              onBlur={(data) => {
                setInfo({ ...info, message: data.target.value });
              }}
            ></textarea>
            <button
              className="button"
              onClick={() => {
                postItem("/popup/insert", info);
              }}
            >
              팝업 추가
            </button>
          </div>

          <div className="form">
            <h1>팝업 관리</h1>

            <Table headerList={depositHeaderList} rowList={list} />
          </div>
        </div>
        <PageNav
          setPer={setPer}
          total={total}
          pageMax={pageMax}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </div>
  );
};

export default PopupAdmin;
