const Footer = () => {
  return (
    <div class="footer">
      <div class="copyright">
        <br />
        <span>두랭이해변오토캠핑장</span>
        <br />
        <span>전남 여수시 율촌면 두언길 106-51</span>

        <div class="contact">
          <span></span>
          <br />
          <span> </span>
        </div>

        <br />
        <span>
          사업자등록번호 : 388-21-01700 <br />
          관광사업등록번호 : 제 2023-00001호
        </span>
      </div>
    </div>
  );
};
export default Footer;
