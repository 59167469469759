import { useEffect, useState } from "react";
import "./style.css"
import getList from "../../axios/getList";
import styled from "styled-components";
import { Link } from "react-router-dom";

const Img = styled.div`
background-image: url("${props => props.url}");
cursor: pointer;

`
const CampInfo = () => {
    const [campList, setCampList] = useState([])
    useEffect(() => {
        getList("/camp/listAll").then(async data => {
            const list = []
            for (let i = 0; i < data.length; i++) {
                if (i % 3 == 0) {
                    list.push(
                        <div key={"info" + data[i].idx} className="item">
                            <div>
                                <Img onClick={() => { window.location.href = "/reservation/" + data[i].idx }} url={data[i].img_1} className="img"></Img>
                                <h2><Link to={"/reservation/" + data[i].idx}>{data[i].site_name}</Link>
                                    <span>{parseInt(data[i].start_price).toLocaleString()}~{parseInt(data[i].end_price).toLocaleString()}원</span></h2>
                                <p>{data[i].title.split(" : ").map(data=><>{data}<br /></>)}</p>
                            </div>
                            {data[i + 1] ?
                                <div>
                                    <Img onClick={() => { window.location.href = "/reservation/" + data[i + 1].idx }} url={data[i + 1].img_1} className="img"></Img>
                                    <h2><Link to={"/reservation/" + data[i + 1].idx}>{data[i + 1].site_name}</Link>
                                        <span>{parseInt(data[i + 1].start_price).toLocaleString()}~{parseInt(data[i + 1].end_price).toLocaleString()}원</span></h2>
                                        <p>{data[i+1].title.split(" : ").map(data=><>{data}<br /></>)}</p>
                                </div>

                                : <div>
                                </div>}
                            {data[i + 2] ?
                                <div>
                                    <Img onClick={() => { window.location.href = "/reservation/" + data[i + 2].idx }} url={data[i + 2].img_1} className="img"></Img>
                                    <h2><Link to={"/reservation/" + data[i + 2].idx}>{data[i + 2].site_name}</Link>
                                        <span>{parseInt(data[i + 2].start_price).toLocaleString()}~{parseInt(data[i + 2].end_price).toLocaleString()}원</span></h2>
                                        <p>{data[i+2].title.split(" : ").map(data=><>{data}<br /></>)}</p>
                                </div>

                                : <div>
                                </div>}

                        </div>
                    )
                }

            }
            setCampList(list)
        })
    }, [])
    return (
        <div className="info-div camp">
            <div className="info-title"> 캠핑장 안내</div>
            {campList}


        </div>
    )
}
export default CampInfo;