import { useState } from "react";
import { useEffect } from "react";
import getList from "../../axios/getList";
import styled from "styled-components";

const Popup = () => {
  const [popupState, setPopupState] = useState(true);
  const [popup, setPopup] = useState({
    title: "내용 없음",
    content: "내용 없음",
  });

  useEffect(() => {
    getList("/notice/view-pop").then((data) => {
      setPopup(data);
    });
  }, []);
  return (
    <>
      <Modal
        className="reservation-form"
        style={{ display: popupState ? "block" : "none" }}
      >
        <div className="reservation-form" style={{ top: "30vh" }}>
          <p onClick={() => setPopupState(false)}>X</p>
          <h1>{popup.title}</h1>
          <div className="use-info">
            {popup.content
              .toString()
              .split("\n")
              .map((data, idx) => (
                <span key={new Date().getTime() + idx}>
                  {data}
                  <br />
                </span>
              ))}
          </div>
          <div className="button-wrap">
            <button onClick={() => setPopupState(false)}>닫기</button>
          </div>
        </div>
      </Modal>
    </>
  );
};
const Modal = styled.div`
  display: ${(props) => (props.state ? "block" : "none")};
  position: absolute;
  top: -70px;
  left: 0;
  z-index: 100000;
  & h1 {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 20px;
  }
  & p {
    text-align: right;
    font-size: 15px !important;
    padding: 0 7%;
    color: #b4c0d3;
    position: absolute;
    right: 32px;
    top: 30px;
    z-index: 100000;
  }
  & .use-info {
    margin: 0;
    min-height: 20vh;
  }
  & .use-info span {
    font-weight: 400 !important;
  }
  & .button-wrap {
    margin: 0;
  }
  & .button-wrap button {
    width: fit-content;
    padding: 5px 36px;
    border-radius: 4px;
    vertical-align: middle;
    box-sizing: border-box;
  }
  & .button-wrap button:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: calc(100% + 70px);
`;
export default Popup;
