import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./style.css"
import styled from "styled-components"
import LeftButton from "../img/leftButton.png";
import RightButton from "../img/rightButton.png";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import getList from "../../axios/getList";

const Img = styled.div`
background-image: url("${props => props.url}");
cursor: pointer;

`
const InfoSlider = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };
    const [campList, setCampList] = useState([])
    useEffect(() => {
        getList("/camp/listAll").then(async data => {
            const list = []
            for (let i = 0; i < data.length; i++) {
                if (i % 3 == 0) {
                    
                    list.push(

                        <div key={"info" + data[i].idx}  className="slick-item">
                            <div>
                                <Img onClick={()=>{window.location.href="/reservation/" + data[i].idx}} url={data[i].img_1} className="img"></Img>
                                <h2><Link to={"/reservation/" + data[i].idx}>{data[i].site_name}</Link>
                                    <span>{parseInt(data[i].start_price).toLocaleString()}~{parseInt(data[i].end_price).toLocaleString()}원</span></h2>
                                <p><>{data[i].title.split(" : ").map((data,idx)=><span key={data[i].idx+"sub"+idx}>{data}<br /></span>)}</></p>
                            </div>
                            {data[i + 1] ?
                                <div>
                                    <Img onClick={()=>{window.location.href="/reservation/" + data[i + 1].idx}} url={data[i + 1].img_1} className="img"></Img>
                                    <h2><Link to={"/reservation/" + data[i + 1].idx}>{data[i + 1].site_name}</Link>
                                        <span>{parseInt(data[i + 1].start_price).toLocaleString()}~{parseInt(data[i + 1].end_price).toLocaleString()}원</span></h2>
                                        <p><>{data[i+1].title.split(" : ").map((data,idx)=><span key={data[i+1].idx+"sub"+idx}>{data}<br /></span>)}</></p>
                                </div>

                                : <div>
                                </div>}
                            {data[i + 2] ?
                                <div>
                                    <Img onClick={()=>{window.location.href="/reservation/" + data[i+2].idx}} url={data[i + 2].img_1} className="img"></Img>
                                    <h2><Link to={"/reservation/" + data[i + 2].idx}>{data[i + 2].site_name}</Link>
                                        <span>{parseInt(data[i + 2].start_price).toLocaleString()}~{parseInt(data[i + 2].end_price).toLocaleString()}원</span></h2>
                                        <p><>{data[i+2].title.split(" : ").map((data,idx)=><span key={data[i+2].idx+"sub"+idx}>{data}<br /></span>)}</></p>
                                </div>

                                : <div>
                                </div>}


                        </div>
                    )
                }

            }
            setCampList(list)
        })
    }, [])

    return (
        <InfoSlideDiv className="info-div">
            <div className="info-title"> 캠핑장 안내</div>

            <Slider className="camp-info" {...settings}>
                {campList}

            </Slider>
        </InfoSlideDiv>
    )
}
const InfoSlideDiv = styled.div`
background-color : #F9FAFF !important;
    & .slick-prev{
        background : url("${LeftButton}");
    }
    & .slick-next{
        background : url("${RightButton}");
    }
`
export default InfoSlider;