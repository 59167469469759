import axios from "axios";
let editInfo = {};
export const setEditInfoPost = (key, value) => {
  editInfo[key] = value;
};
export const loginPost = (loginInfo) => {
  const headers = {
    pw: loginInfo.pw,
    id: loginInfo.id,
  };
  return axios
    .post("http://doorangicamping.com:8080/login", {}, { headers })
    .then((data) => data.headers.authorization);
};
const postItem = (url, params) => {
  if (params) {
    axios
      .post("http://doorangicamping.com:8080/admin" + url, { ...params })
      .then((data) => {
        alert(data.data);
        window.location.reload();
      });
  } else {
    axios
      .post("http://doorangicamping.com:8080/admin" + url, { ...editInfo })
      .then((data) => {
        alert(data.data);
        window.location.reload();
      });
  }
};
export default postItem;
