import { useEffect, useState } from "react"
import "./style.css"
import getList from "../../axios/getList"

const UseInfo = () => {
    const [useList, setUseList] = useState([])
    useEffect(() => {
        getList("/use/list").then(data => {
            const list = []
            for (let i = 0; i < data.length; i++) {
                const contentList = data[i].content.split("\n");
                const liList = [];
                for (let j = 0; j < contentList.length; j++) {
                    liList.push(<li key={"use-li-"+j}>{contentList[j]}</li>);
                }
                list.push(
                    <div key={"use" + i} className="item">
                        <div className="title">{data[i].title}</div>
                        <div className="content">
                            <ul>
                                {liList}
                            </ul>
                        </div>
                    </div>
                )
            }
            setUseList(list)
        })
    }, [])

    return (
        <div className="info-div use">
            <div className="info-title"> 이용안내</div>
            <div className="use-div">
                {useList}
            </div>
        </div>
    )
}
export default UseInfo;