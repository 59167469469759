import { useEffect, useState } from "react"
import insertFileAndParams, { insertFiles } from "../../axios/insertFile"
import postItem from "../../axios/post"
import CampSubAdmin from "./campSubAdmin"
import getList from "../../axios/getList"
import styled from "styled-components"
import deleteItem from "../../axios/delete"
const Img = styled.div`
background-image: url("${props => props.url}");
width : 50%;
flex : 1;
max-width  :50%;
padding : 0;
padding-bottom : 35% !important;
margin : 5px 10px;
& input {
    width : 100%;

}
`
const CampAdmin = () => {
    const [info, setInfo] = useState({ siteName: null, content: null })
    const [campList, setCampList] = useState([])
    const [editList, setEditList] = useState({})
    useEffect(() => {
        if(!window.sessionStorage.getItem("jwt")){
            alert("로그인이 필요합니다!")
            window.location.href="/admin"
        }
        getList("/camp/list").then(async data => {
            const list = []
            for (let i = 0; i < data.length; i++) {
                    const subList1 = []
                    await getList("/camp_sub/list?siteIdx=" + data[i].id).then(subdata => {
                        for (let j = 0; j < subdata.length; j++) {
                            subList1.push(<div style={{margin:"10px 0"}} key={"subinfo" + subdata[j].id}> 
                            <h1>서브{j+1}</h1><br />
                            {subdata[j].title}  <br /><input id={"title"+subdata[j].title+subdata[j].id}></input><br />
                            {subdata[j].content}<br /><input id={"content"+subdata[j].content+subdata[j].id}></input><br />
                            <button style={{width:"100%"}} className="button"
                            onClick={()=>{
                                const title = document.getElementById("title"+subdata[j].title+subdata[j].id).value
                                const content = document.getElementById("content"+subdata[j].content+subdata[j].id).value

                                const idx = subdata[j].id;
                                const infoData = { idx,content,title }
                                postItem("/camp_sub/update", infoData)
                            }}>수정하기</button>
                            <button 
                            style={{width:"100%"}} className="button"
                            onClick={()=>{deleteItem("/camp_sub/delete",subdata[j].id)}}>캠핑장 설명 삭제하기</button><br />
                            </div>)
                        }
                    })
                    list.push(
                        <div key={"info" + data[i].id} className="item" style={{width:"calc(100% / 3)",display:"inline-block",verticalAlign:"top",marginBottom:"30px"}}>
                            <div>
                            <h1>{data[i].siteName}</h1>
                                <div style={{display:"flex"}}>
                                <Img style={{flex:1}} url={data[i].img1} className="img"><input type='file' id={"file1-"+data[i].id} name='uploadFile'></input></Img>
                                <Img style={{flex:1}} url={data[i].img2} className="img"><input type='file' id={"file2-"+data[i].id} name='uploadFile'></input></Img>
                                </div>
                                <div style={{display:"flex"}}>
                                <Img url={data[i].img3} className="img"><input type='file' id={"file3-"+data[i].id} name='uploadFile'></input></Img>
                                <Img url={data[i].img4} className="img"><input type='file' id={"file4-"+data[i].id} name='uploadFile'></input></Img>
                                </div>
                                <h2>{data[i].siteName}</h2>
                                <input placeholder="사이트 이름" style={{width:"100%"}} id={"siteName"+data[i].siteName}></input>
                                <h2>
                                <span>{data[i].startPrice}
                                ~{data[i].endPrice}</span>
                                </h2>
                                <span>
                                    <input placeholder="주중가격" style={{width:"48%"}} id={"startPrice"+data[i].startPrice}></input>
                                ~
                                <input placeholder="주말가격" style={{width:"48%"}} id={"endPrice"+data[i].endPrice}></input>
                                </span>
                                <br/><br/>
                                <h2>소개글</h2>                       

                                <div> <br /> {data[i].info.split("\n").map(data=><>{data}<br/></>)} 
                                <br/>
                                <textarea id={"info"+data[i].info}></textarea>    
                                <button className="button" style={{width:"100%"}}
                                onClick={() => {
                                    const siteName = document.getElementById("siteName"+data[i].siteName).value
                                    const infoText = document.getElementById("info"+data[i].info).value
                                    const endPrice = document.getElementById("endPrice"+data[i].endPrice).value
                                    const startPrice = document.getElementById("startPrice"+data[i].startPrice).value
                                    insertFiles(["file1-"+data[i].id,
                                    "file2-"+data[i].id,
                                    "file3-"+data[i].id,
                                    "file4-"+data[i].id], "/camp/insert_imgs").then(imgdata => {
                                        const infoData = { idx:data[i].id,content:infoText,endPrice,startPrice,siteName, img1: imgdata[0], img2: imgdata[1], img3: imgdata[2], img4: imgdata[3] }
                                        postItem("/camp/update", infoData)
                                    })
                                }}
                                >위 내용으로 수정하기</button>
                                <button style={{width:"100%"}} className="button" onClick={()=>{deleteItem("/camp/delete",data[i].id);}}>캠핑장 삭제하기</button>
                                <div>{subList1}</div>
                                <CampSubAdmin siteIdx={data[i].id} />

                                </div>
                            </div>
                        </div>
                    )

            }
            setCampList(list)
        })
    }, [])

    return (
        <div className="info-div camp admin">
                            <div className="content" style={{width : "50%",margin : "0 auto"}}>
            <h1  style={{width:"100%",textAlign:"center"}}>새로운 요소 추가</h1>
            <div className="form-item"><label>img1</label><input style={{width:"70%"}}  type='file' id="file1" name='uploadFile'></input></div>
            <div className="form-item"><label>img2</label><input style={{width:"70%"}}  type='file' id="file2" name='uploadFile'></input></div>
            <div className="form-item"><label>img3</label><input style={{width:"70%"}}  type='file' id="file3" name='uploadFile'></input></div>
            <div className="form-item"><label>img4</label><input style={{width:"70%"}}  type='file' id="file4" name='uploadFile'></input></div>
            <div className="form-item"><label>사이트이름</label>  <input style={{width:"70%"}} name='title' onBlur={(data) => { setInfo({ ...info, siteName: data.target.value }) }}></input></div>  
            <div className="form-item"><label>사이트 상세설명</label>   <input style={{width:"70%"}} name='content' onBlur={(data) => { setInfo({ ...info, content: data.target.value }) }}></input></div>   
            <div className="form-item"><label>주중가격</label><input style={{width:"70%"}} name='startPrice' onBlur={(data) => { setInfo({ ...info, startPrice: data.target.value }) }}></input></div>
            <div className="form-item"><label>주말가격</label>  <input style={{width:"70%"}} name='endPrice' onBlur={(data) => { setInfo({ ...info, endPrice: data.target.value }) }}></input></div>  
            <button className="button" onClick={() => {
                insertFiles(["file1", "file2", "file3", "file4"], "/camp/insert_imgs").then(data => {
                    const infoData = { ...info, img1: data[0], img2: data[1], img3: data[2], img4: data[3] }
                    postItem("/camp/insert", infoData);
                })
            }}>추가하기</button>
            </div>
            {campList}

        </div>

    )
}
export default CampAdmin