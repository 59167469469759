import styled from "styled-components";
import react, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Table = (props) => {
  const [headerListState, setHeaderListState] = useState(null);
  const [rowListState, setRowListState] = useState([]);
  useEffect(() => {
    const headerList = [];
    const rowList = [];
    let count = 0;
    props.headerList.map((data) => {
      count = count + 1;
      headerList.push(
        <SCell key={"Header" + count} header={true}>
          {data.data}
        </SCell>
      );
    });
    try {
      let count = 0;
      props.rowList.map((row) => {
        const cellList = [];
        count = count + 1;
        let headerCount = 0;
        props.headerList.map((data) => {
          headerCount = count * headerCount + 1;
          if (data.type == "text") {
            cellList.push(
              <SCell
                style={{ textAlign: "left", padding: "0 20px" }}
                key={count + "" + headerCount}
              >
                {row[data.columnName]}
              </SCell>
            );
          }
          if (data.type == "state") {
            cellList.push(
              <SCell
                style={{ textAlign: "center", padding: "0 20px" }}
                key={count + "" + headerCount}
              >
                {row[data.columnName] ? "승인 완료" : "대기중"}
              </SCell>
            );
          }
          if (data.type == "link") {
            cellList.push(
              <SCell
                style={{ textAlign: "left", padding: "0 20px" }}
                key={count + "" + headerCount}
              >
                <Link
                  style={{ textDecoration: "none", color: "black" }}
                  to={"/customer-content/" + row["id"]}
                >
                  {row[data.columnName]}
                </Link>
              </SCell>
            );
          }

          if (data.type == "date") {
            cellList.push(
              <SCell
                width={"20%"}
                style={{ textAlign: "left", padding: "0 20px" }}
                key={count + "" + headerCount}
              >
                {data.columnName != "endDate"
                  ? new Date(row[data.columnName])
                      .toLocaleDateString()
                      .replaceAll(". ", "-")
                      .replaceAll(".", " ")
                  : new Date(
                      new Date(row[data.columnName]).getTime() + 86400000
                    )
                      .toLocaleDateString()
                      .replaceAll(". ", "-")
                      .replaceAll(".", " ")}
              </SCell>
            );
          } else if (data.type == "number") {
            cellList.push(
              <SCell
                key={count + "" + headerCount}
                style={{ textAlign: "center" }}
              >
                {row[data.columnName]}
              </SCell>
            );
          }
          if (data.type == "setting") {
            cellList.push(
              <SCell
                style={{ textAlign: "center", padding: "0" }}
                key={count + "" + headerCount}
              >
                {row[data.columnName]}
              </SCell>
            );
          }
        });
        rowList.push(
          <SRow key={count + "row"} flag={row.flag} height="40px;">
            {cellList}
          </SRow>
        );
      });
    } catch (e) {
      rowList.push(
        <SRow key={"noDataRow"}>
          <SCell>no data</SCell>
        </SRow>
      );
    }

    setHeaderListState(headerList);
    setRowListState(rowList);
  }, [props.rowList]);
  return (
    <STableWrapper>
      <STable>
        <thead>
          <SRow key={"headerRow"} height="50px;">
            {headerListState}
          </SRow>
        </thead>
        <tbody>
          {props.children}
          {rowListState}
        </tbody>
      </STable>
    </STableWrapper>
  );
};
const STableWrapper = styled.div`
  width: 100%;
  background: white;
  min-height: 10px;
  border-top: 2px solid #333333;

  height: ${(props) => props.size * 40 + 80}px;
  max-height: 99%;
  -webkit-transition: height 0.3s, max-height 0.3s;
  transition: height 0.3s, max-height 0.3s;
  overflow: scroll;
  &::-webkit-scrollbar {
    width: 4px;
    height: 0px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 12px;
    background: rgb(97, 137, 255);
    &:hover {
      background: blue;
    }
  }
  @media (max-width: 500px) {
    & {
      border-top: 1px solid rgb(235, 238, 245);
      margin-bottom: 30px;
    }
  }
`;
const SCell = styled.td`
  vertical-align: middle;
  padding: 5px 10px;
  box-sizing: border-box;
  width: fit-content;
  text-align: center;
  border-bottom: 1px solid #ebeef5;
  font-size: 14px;
  color: ${(props) => (props.header ? "#8f8f8f;" : "black")};
  font-weight: ${(props) => (props.header ? "bold;" : "normal")};
  white-space: nowrap;
  width: fit-content;
`;
const SRow = styled.tr`
  display: table-row;
  height: ${(props) => props.height};
  box-sizing: border-box;
  background: ${(props) => (props.flag == "0" ? "red" : null)};
`;
const STable = styled.table`
  display: table;
  width: 100%;
`;

export default Table;
