import axios from "axios";

const insertFileAndParams = (fileId, params, url) => {
  let fileInput = document.getElementById(fileId);
  let fileObj = fileInput.files[0];
  let formData = new FormData();
  formData.append("uploadFile", fileObj);

  const paramKeys = Object.keys(params);
  for (let i = 0; i < paramKeys.length; i++) {
    formData.append(paramKeys[i], params[paramKeys[i]]);
  }
  return axios
    .post("http://doorangicamping.com:8080/admin" + url, formData)
    .then((data) => {
      console.log(data.data);
      window.location.reload();
    });
};
export const insertFiles = (fileId, url) => {
  let formData = new FormData();
  for (let i = 0; i < fileId.length; i++) {
    let fileInput = document.getElementById(fileId[i]);
    let fileObj = fileInput.files[0];
    formData.append("uploadFile" + (i + 1), fileObj);
  }
  return axios
    .post("http://doorangicamping.com:8080/admin" + url, formData)
    .then((data) => {
      return data.data;
    });
};

export default insertFileAndParams;
