import styled from "styled-components"
import react, { useEffect, useState } from "react"
const PageNav = (props)=>{
    const [pageList, setPageList] = useState(null)
    useEffect(()=>{
        const list = []
        let count = parseInt(props.currentPage/6);
        for(let i = count * 6; i<=count * 6 + 6 && i<props.pageMax; i++){

            if(i==0) i=i+1;
            list.push(
                <SPageButton key={i} onClick={()=>props.setCurrentPage(i)} current={props.currentPage==i?true:false}>
                    <SPageButtonText>
                    {i}
                     </SPageButtonText>
                 </SPageButton>)
            if(i==props.pageMax){break;}
        }
        setPageList(list)
    },[props.pageMax,props.currentPage])
    const backButtonfunc = (currentPage)=>{
        if(currentPage - 6 > 0 ){
            if(currentPage % 6 == 0 ){
                return currentPage - 6
            }
            else{
                return currentPage - 1 - (currentPage % 6) 
            }
                    }
        else{
            return 1
        }
    }
    const frontButtonfunc = (currentPage)=>{
        if(currentPage + 6 > props.pageMax ){
            return props.pageMax
        }
        else{
            return currentPage + 6
        }
    }
    if(props.pageMax>0)
    return (
                <SPageNationWrapper>
                    <SPageNationInnerWrapper>
                        <SPageButton onClick={()=>props.setCurrentPage(backButtonfunc(props.currentPage))}>
                            {`<`}
                        </SPageButton>
                        {pageList}
                        
                        <SGotoWrapper >
                            {props.pageMax==1?null:<SPageButton onClick={()=>props.setCurrentPage(props.pageMax)} current={props.currentPage==props.pageMax?true:false}>
                        <SPageButtonText >
                        {props.pageMax}
                        </SPageButtonText>
                        </SPageButton>
}
                        <SPageButton onClick={()=>props.setCurrentPage(frontButtonfunc(props.currentPage))}>
                            <SLogo>{`>`}</SLogo>
                        </SPageButton>

                        </SGotoWrapper>

                    </SPageNationInnerWrapper>
                </SPageNationWrapper>
    );
    else{
        return <></>
    }
}

const SGotoWrapper =styled.div`
display : inline-block;
height : 100%;
`
const SPageButtonText = styled.div`
vertical-align :middle;
line-height : 26px;
`
const SLogo = styled.div`
width : 28px;
height : 28px;
display : inline-block;
margin : 0 auto ;
`
const SPageButton=styled.div`
    min-width : 30px;
    height : 100%;
    margin : 0 5px;
    display : inline-block;
    text-align : center;
    background : ${props => props.current ? "#6189FF":"rgba(0,0,0,0)"};
    color : ${props => props.current ? "#fff":"rgba(0,0,0,1)"};
    font-size : 13px;
    vertical-align : middle;
    line-height : 30px;
    border-radius : 5px;
    -webkit-transition:background .1s;
    transition:background .1s;
    &:hover{
        background : #E9F2FF;
        color : white;
    }
`

const SPageNationInnerWrapper =styled.div`
    padding : 2px 5px;
    box-sizing : border-box;
    height : 32px;
    width : 100%;
    margin : 0 auto;
    display : flex;
    align-items:center;
    justify-content: center;
`
const SPageNationWrapper = styled.div`
padding : 2px 16px;

`
export default PageNav
