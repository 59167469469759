import { useEffect, useState } from "react"
import insertFileAndParams, { insertFiles } from "../../axios/insertFile"
import postItem from "../../axios/post"

const CampSubAdmin = (props)=>{
    const [info, setInfo] = useState({ siteName: null, content: null })
    useEffect(()=>{
        setInfo({ ...info, siteIdx: props.siteIdx })
    },[])
    return (
        <div > 
            <p>새로운 서브 요소 추가</p>
            <br/>
            <div className="form-item"><label>서브제목</label>  <input placeholder="서브제목" name='startPrice' onBlur={(data) => { setInfo({ ...info, title: data.target.value }) }}></input></div>  
            <div className="form-item"><label>서브내용</label>  <input placeholder="서브내용" name='content' onBlur={(data) => { setInfo({ ...info, content: data.target.value }) }}></input></div>

            <button  style={{width:"100%"}} className="button" onClick={() =>{
                postItem("/camp_sub/insert",info)
            }}>서브내용 추가하기</button>
        </div>

    )
}
export default CampSubAdmin