import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Header from "./component/header";
import MainSlider from "./component/main/mainSlider";
import InfoMain from "./component/main/info";
import CampInfo from "./component/main/campInfo";
import InfoSlider from "./component/main/infoSlide";
import Reservation from "./component/main/reservation";
import UseInfo from "./component/main/useInfo";
import Customer from "./component/main/customer";
import UseAdmin from "./component/admin/useAdmin";
import ReservationSetting from "./component/admin/reservationSetting";
import CustomerContent from "./component/main/customerContent";
import ReservationCheckingAdmin from "./component/admin/reservationCheckingAdmin";
import NoticeAdmin from "./component/admin/noticeAdmin";
import SlideAdmin from "./component/admin/slideAdmin";
import MainImgAdmin from "./component/admin/mainImgAdmin";
import CampAdmin from "./component/admin/campAdmin";
import AdminSetting from "./component/admin/adminLogin";
import AdminHeader from "./component/adminHeader";
import Footer from "./component/footer";
import CampInfoReservation from "./component/main/campInfoReservation";
import PopupAdmin from "./component/admin/popupAdmin";
const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <>
        <Header />
        <MainSlider />
        <InfoMain />
        <InfoSlider />
        <Footer />
      </>
    ),
  },
  {
    path: "/reservation-current",
    element: (
      <>
        <Header />
        <CampInfoReservation />
        <Footer />
      </>
    ),
  },
  {
    path: "/list",
    element: (
      <>
        <Header />
        <CampInfo />
        <Footer />
      </>
    ),
  },
  {
    path: "/reservation/:idx",
    element: (
      <>
        <Header />
        <Reservation />
        <Footer />
      </>
    ),
  },
  {
    path: "/use-info",
    element: (
      <>
        <Header />
        <UseInfo />
        <Footer />
      </>
    ),
  },
  {
    path: "/customer",
    element: (
      <>
        <Header />

        <Customer />
        <Footer />
      </>
    ),
  },
  {
    path: "/customer-content/:idx",
    element: (
      <>
        <Header />
        <CustomerContent />
        <Footer />
      </>
    ),
  },
  {
    path: "/reservation-setting-admin",
    element: (
      <>
        <AdminHeader />

        <ReservationSetting />
      </>
    ),
  },
  {
    path: "/reservation-check-admin",
    element: (
      <>
        <AdminHeader />

        <ReservationCheckingAdmin />
      </>
    ),
  },
  {
    path: "/notice-admin",
    element: (
      <>
        <AdminHeader />

        <NoticeAdmin />
      </>
    ),
  },
  {
    path: "/popup-admin",
    element: (
      <>
        <AdminHeader />

        <PopupAdmin />
      </>
    ),
  },
  {
    path: "/slide-admin",
    element: (
      <>
        <AdminHeader />
        <MainSlider />
        <SlideAdmin />
      </>
    ),
  },
  {
    path: "/main-admin",
    element: (
      <>
        <AdminHeader />
        <MainImgAdmin />
      </>
    ),
  },
  {
    path: "/camp-admin",
    element: (
      <>
        <AdminHeader />
        <CampAdmin />
      </>
    ),
  },
  {
    path: "/use-admin",
    element: (
      <>
        <AdminHeader />
        <UseAdmin />
      </>
    ),
  },
  {
    path: "/admin",
    element: (
      <>
        <AdminHeader />
        <AdminSetting />
      </>
    ),
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
