import { useEffect, useState } from "react";
import postItem from "../../axios/post";
import getList from "../../axios/getList";
import Table from "../Atoms/table";
import PageNav from "../Atoms/pageNav";
import deleteItem from "../../axios/delete";
import { setEditInfoPost } from "../../axios/post";

const NoticeAdmin = () => {
  const [info, setInfo] = useState({ title: null, content: null });
  const [per, setPer] = useState(10);
  const [total, setTotal] = useState(10);
  const [pageMax, setPageMax] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [list, setList] = useState([]);
  const depositHeaderList = [
    { type: "number", data: "No", columnName: "id" },
    { type: "link", data: "제목", columnName: "title" },
    { type: "number", data: "조회수", columnName: "view" },
    { type: "date", data: "작성시간", columnName: "date" },
    { type: "setting", data: "수정", columnName: "edit" },
  ];
  useEffect(() => {
    if (!window.sessionStorage.getItem("jwt")) {
      alert("로그인이 필요합니다!");
      window.location.href = "/admin";
    }
    getList("/notice/total").then((data) => {
      setTotal(data);
      setPageMax((data / per + 1).toFixed(0));
    });
    getList("/notice/list", currentPage - 1, per).then((data) => {
      setEditInfoPost("title", null);
      setEditInfoPost("content", null);
      for (let i = 0; i < data.length; i++) {
        data[i].edit = (
          <div className="form edit">
            제목
            <br />
            <input
              name="title"
              onBlur={(data) => {
                setEditInfoPost("title", data.target.value);
              }}
            ></input>
            <br />
            내용
            <br />{" "}
            <textarea
              name="content"
              onBlur={(data) => {
                setEditInfoPost("content", data.target.value);
              }}
            ></textarea>
            <br />
            <button
              style={{ width: "100%" }}
              className="button"
              onClick={() => {
                postItem("/notice/update-pop", { idx: data[i].id });
              }}
            >
              이 공지사항을 팝업으로 설정
            </button>
            <button
              style={{
                width: "49%",
                margin: 0,
                marginRight: "2%",
                display: "inline-block",
              }}
              className="button"
              onClick={() => {
                setEditInfoPost("idx", data[i].id);
                postItem("/notice/update", null);
              }}
            >
              수정
            </button>
            <button
              style={{ width: "49%", margin: 0, display: "inline-block" }}
              className="button"
              onClick={() => {
                deleteItem("/notice/delete", data[i].id);
              }}
            >
              삭제
            </button>
          </div>
        );
      }
      setList(data);
    });
  }, [total, currentPage]);
  return (
    <div>
      <div className="admin notice">
        <div className="admin notice wrapper">
          <div className="form add">
            <h1>새로운 공지사항</h1>
            제목 <br />{" "}
            <input
              name="title"
              onBlur={(data) => {
                setInfo({ ...info, title: data.target.value });
              }}
            ></input>
            <br />
            내용{" "}
            <textarea
              name="content"
              onBlur={(data) => {
                setInfo({ ...info, content: data.target.value });
              }}
            ></textarea>
            <button
              className="button"
              onClick={() => {
                postItem("/notice/insert", info);
              }}
            >
              공지사항 추가
            </button>
          </div>

          <div className="form">
            <h1>공지사항 관리</h1>

            <Table headerList={depositHeaderList} rowList={list} />
          </div>
        </div>
        <PageNav
          setPer={setPer}
          total={total}
          pageMax={pageMax}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </div>
  );
};

export default NoticeAdmin;
