import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./style.css";
import styled from "styled-components";
import LeftButton from "../img/leftButton.png";
import RightButton from "../img/rightButton.png";
import Slider from "react-slick";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css"; // css import
import { useState } from "react";
import { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import getList from "../../axios/getList";
import postItem from "../../axios/post";
const Img = styled.div`
  background-image: url("${(props) => props.url}");
`;
const Reservation = () => {
  const [value, onChange] = useState(
    new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate()
    )
  );
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [count, setCount] = useState(0);
  const [beforeList, setBeforeList] = useState([]);
  const { idx } = useParams();
  const [reservationModal, setReservationModal] = useState(false);
  const [imgModal, setImgModal] = useState(false);
  const [userCheck, setUserCheck] = useState(false);
  const [price, setPrice] = useState(0);
  const [reservationStartDate, setReservationStartDate] = useState(0);

  const [imgSlideItems, setImgSlideItems] = useState([]);
  const [imgItems, setImgItems] = useState([]);
  const [infos, setInfos] = useState({
    info: "",
    siteName: "",
    endPrice: "",
    startPrice: "",
  });
  const [subInfos, setSubInfos] = useState([]);
  const [days, setDays] = useState(0);
  const [priceValueList, setPriceValueList] = useState([]);
  const [trueValueList, setTrueValueList] = useState([]);
  const [falseValueList, setFalseValueList] = useState([]);
  const [canNotReservation, setCanNotReservation] = useState([]);
  const [reservationInfos, setReservationInfos] = useState({
    name: "",
    phone: "",
    carNumber: "",
    many: "",
  });
  const [current, setCurrent] = useState(0);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const formatDay = (locale) => {
    const dateData = new Date(locale);
    return dateData.getDate();
  };
  const setDate = (date) => {
    if (count % 2 == 0) {
      setStartDate(date);
    } else {
      setEndDate(date);
    }
    setCount(count + 1);
  };

  useEffect(() => {
    getList("/reservation/list?idx=" + idx).then((data) => {
      const listTemp = [];
      for (let i = 0; i < data.length; i++) {
        const distanceEnd =
          (new Date(data[i].endDate).getTime() -
            new Date(data[i].startDate).getTime()) /
          86400000;
        listTemp.push(new Date(data[i].startDate).getTime());

        for (let j = 1; j < distanceEnd; j++) {
          listTemp.push(new Date(data[i].startDate).getTime() + j * 86400000);
        }

        listTemp.push(new Date(data[i].endDate).getTime());
      }
      getList("/setting/setting-list?idx=" + idx + "&flag=5").then((data) => {
        for (let i = 0; i < data.length; i++) {
          const distanceEnd =
            new Date(
              data[i].dateList.split(",")[
                data[i].dateList.split(",").length - 1
              ] - new Date(data[i].startDate).getTime()
            ).getTime() / 86400000;
          listTemp.push(new Date(data[i].startDate).getTime());

          for (let j = 1; j <= Math.ceil(distanceEnd); j++) {
            listTemp.push(new Date(data[i].startDate).getTime() + j * 86400000);
          }
        }
        setCanNotReservation(listTemp);
      });
    });

    getList("/setting/setting-list?idx=" + idx + "&flag=3").then((data) => {
      const dataObj = {};
      for (let i = 0; i < data.length; i++) {
        dataObj[data[i].dateList] = parseInt(data[i].price);
      }
      setPriceValueList(dataObj);
    });
    getList("/setting/setting-list?idx=" + idx + "&flag=4").then((data) => {
      console.log(data);
      setReservationStartDate(new Date(data[0].startDate).getTime());
    });
  }, []);
  useEffect(() => {
    const tempTrueValueList = [];
    let reservationFlag = false;
    if (value.getDay() >= 3) {
      for (let i = 0; i <= 7 - value.getDay(); i++) {
        const tempDate = new Date(value.getTime() + 86400000 * i).getTime();
        tempTrueValueList.push(tempDate);
      }
    }
    for (let i = 0; i < tempTrueValueList.length; i++) {
      if (canNotReservation.includes(tempTrueValueList[i])) {
        reservationFlag = true;
      }
    }
    if (reservationFlag) {
      while (tempTrueValueList.length > 0) {
        tempTrueValueList.pop();
      }
    }
    getList("/setting/setting-list?idx=" + idx + "&flag=1").then((data) => {
      const listTemp = [];
      for (let i = 0; i < data.length; i++) {
        const dataList = data[i].dateList.split(",");
        for (let j = 0; j < dataList.length; j++) {
          listTemp.push(parseInt(dataList[j]));
        }
      }
      setTrueValueList([...tempTrueValueList, ...listTemp]);
    });
    getList("/setting/setting-list?idx=" + idx + "&flag=0").then((data) => {
      const listTemp = [];
      for (let i = 0; i < data.length; i++) {
        const dataList = data[i].dateList.split(",");
        for (let j = 0; j < dataList.length; j++) {
          listTemp.push(parseInt(dataList[j]));
        }
      }
      setFalseValueList([...listTemp]);
    });
  }, [canNotReservation]);
  useEffect(() => {
    const calendarView = document.getElementsByClassName(
      "react-calendar__month-view__days"
    );
    const childrenList = calendarView[0].children;
    const distance = (endDate.getTime() - startDate.getTime()) / 86400000;
    const reservationList = [];
    setDays(distance);
    for (let j = 0; j < distance; j++) {
      reservationList.push(
        new Date(startDate.getTime() + 86400000 * j).getTime()
      );
    }
    let sum = 0;
    const priceKeys = Object.keys(priceValueList);
    for (let i = 0; i < reservationList.length; i++) {
      let settingPrice = 0;
      for (let j = 0; j < priceKeys.length; j++) {
        if (priceKeys[j].includes(reservationList[i])) {
          settingPrice = priceValueList[priceKeys[j]];
        }
      }
      if (settingPrice != 0) {
        sum = sum + settingPrice;
      } else if (
        new Date(reservationList[i]).getDay() == 5 ||
        new Date(reservationList[i]).getDay() == 6
      ) {
        sum = sum + infos.endPrice;
      } else {
        sum = sum + infos.startPrice;
      }
    }
    setPrice(sum - (distance > 1 ? distance * 5000 : 0));
    if (startDate.getTime() + 1 <= value.getTime()) {
      alert("시작 일자는 오늘보다 이전일 수 없습니다!");
      setPrice(0);
      setCount(0);
      return;
    }
    if (distance > 5) {
      alert("최대 연박은 5일까지 입니다!");
      setPrice(0);
      setCount(0);
      setDays(0);
      return;
    }

    if (beforeList.length > 0) {
      for (let j = 0; j < beforeList.length; j++) {
        beforeList[j].style.background = "none";
        beforeList[j].style.color = "#313131";
        if (beforeList[j].children[0].innerHTML == new Date().getDate()) {
        }
      }
    }

    /** 연박 설정 **/
    if (
      startDate.getTime() != endDate.getTime() &&
      startDate.getTime() < endDate.getTime()
    ) {
      let flag = false;
      for (let i = 0; i < reservationList.length; i++) {
        if (canNotReservation.includes(reservationList[i])) {
          alert("예약 불가능한 날짜가 끼어있습니다!");
          //return;
        }

        if (trueValueList.includes(reservationList[i])) {
          flag = true;
        }
      }

      if (!flag) {
        let distanceFlag = false;
        for (let i = 0; i < reservationList.length; i++) {
          if (
            new Date(reservationList[i]).getDay() == 6 ||
            new Date(reservationList[i]).getDay() == 5
          ) {
            if (distance > 1) {
              distanceFlag = true;
            }
          } else if (falseValueList.includes(reservationList[i])) {
            if (distance > 1) {
              distanceFlag = true;
            } else {
              distanceFlag = false;
            }
          } else {
            distanceFlag = true;
          }
        }
        if (distanceFlag) {
        } else {
          alert("해당 날짜에는 연박만 가능합니다!");
          return;
        }
      }
    }

    let i = 0;
    let arrowFlag = true;
    for (i = 0; i <= childrenList.length; i++) {
      if (childrenList[i]) {
        const value = childrenList[i].children[0].innerHTML;
        if (
          childrenList[i].children[0].ariaLabel.includes(
            startDate.getMonth() + 2 + "월"
          )
        ) {
          if (value == endDate.getDate()) {
            arrowFlag = false;
            break;
          }
        }
        if (
          childrenList[i].children[0].ariaLabel.includes(
            startDate.getMonth() + 1 + "월"
          )
        ) {
          if (value == startDate.getDate()) {
            break;
          }
        }
      }
    }
    const start = i;
    const before = [];

    if (arrowFlag) {
      for (; i <= start + distance; i++) {
        if (childrenList[i]) {
          const value = childrenList[i];
          value.style.background = "#E9F2FF ";
          value.style.color = "#0066FF";
          before.push(value);
        }
      }
    } else {
      for (; i >= start - distance; i--) {
        if (childrenList[i]) {
          const value = childrenList[i];
          value.style.background = "#E9F2FF ";
          value.style.color = "#0066FF";
          before.push(value);
        }
      }
    }
    setBeforeList(before);
    setReservationInfos({
      ...reservationInfos,
      startDate: startDate.toLocaleDateString(),
      endDate: new Date(
        startDate.getTime() + 86400000 * (distance - 1)
      ).toLocaleDateString(),
      price: sum - (distance > 1 ? distance * 5000 : 0),
      idx: idx,
    });
  }, [canNotReservation, endDate]);
  useEffect(() => {
    if (
      reservationStartDate != 0 &&
      startDate.getTime() >= reservationStartDate
    ) {
      alert("예약이 오픈되지 않은 날짜입니다!");
      setPrice(0);
      setCount(0);
      return;
    }
  }, [startDate]);
  useEffect(() => {
    getList("/camp/info?idx=" + idx).then(async (data) => {
      setInfos(data);
      setImgSlideItems([
        <div className="slick-item">
          <div>
            <Img url={data.img1} className="img"></Img>
          </div>
        </div>,
        <div className="slick-item">
          <div>
            <Img url={data.img2} className="img"></Img>
          </div>
        </div>,
        <div className="slick-item">
          <div>
            <Img url={data.img3} className="img"></Img>
          </div>
        </div>,
        <div className="slick-item">
          <div>
            <Img url={data.img4} className="img"></Img>
          </div>
        </div>,
      ]);
      setImgItems(
        <>
          <div className="reservation-flex">
            <div>
              <Img url={data.img1} className="img"></Img>
            </div>
            <div className="col">
              <div>
                <Img url={data.img2} className="img"></Img>
                <Img url={data.img3} className="img"></Img>
              </div>
              <Img url={data.img4} className="img"></Img>
            </div>
          </div>
        </>
      );
    });
    getList("/camp_sub/list?siteIdx=" + idx).then((data) => {
      const dataList = [];
      for (let i = 0; i < data.length; i++) {
        dataList.push(
          <div key={"info-item" + data[i].id} className="info-item">
            <p>{data[i].title}</p>
            <p>{data[i].content}</p>
          </div>
        );
      }
      setSubInfos(dataList);
    });
  }, []);
  useEffect(() => {
    const calendarView = document.getElementsByClassName(
      "react-calendar__month-view__days"
    );
    const childrenList = calendarView[0].children;
    for (let i = 0; i <= childrenList.length; i++) {
      if (childrenList[i]) {
        const value = childrenList[i].children[0].innerHTML;
        const currentMonth = childrenList[i].children[0].ariaLabel
          .split(" ")[1]
          .split("월")[0];
        const canNotReservationStartMonthString = new Date(reservationStartDate)
          .toLocaleDateString()
          .split(". ")[1];

        for (let j = 0; j < canNotReservation.length; j++) {
          const canNotReservationMonthString = new Date(canNotReservation[j])
            .toLocaleDateString()
            .split(". ")[1];
          if (
            childrenList[i].children[0].ariaLabel.includes(
              canNotReservationMonthString + "월"
            )
          ) {
            if (
              value == new Date(canNotReservation[j]).getDate() &&
              new Date().getTime() <= new Date(canNotReservation[j]).getTime()
            ) {
              const styleTemp = childrenList[i];
              styleTemp.style.background = "#ddd ";
              styleTemp.style.color = "#fff";
            }
          }
        }
        if (
          current != 0 &&
          parseInt(currentMonth) >= parseInt(canNotReservationStartMonthString)
        ) {
          if (
            parseInt(value) >=
              parseInt(new Date(reservationStartDate).getDate()) ||
            parseInt(currentMonth) > parseInt(canNotReservationStartMonthString)
          ) {
            if (
              new Date(reservationStartDate).getTime() <=
              new Date(
                childrenList[i].children[0].ariaLabel
                  .replace("년 ", "-")
                  .replace("월 ", "-")
                  .replace("일", " 00:00:00")
              ).getTime()
            ) {
              const styleTemp = childrenList[i];
              styleTemp.style.background = "#ddd ";
              styleTemp.style.color = "#fff";
            }
          }
        } else if (
          current != 0 &&
          parseInt(currentMonth) <= parseInt(canNotReservationStartMonthString)
        ) {
          if (
            new Date(reservationStartDate).getTime() <=
            new Date(
              childrenList[i].children[0].ariaLabel
                .replace("년 ", "-")
                .replace("월 ", "-")
                .replace("일", " 00:00:00")
            ).getTime()
          ) {
            const styleTemp = childrenList[i];
            styleTemp.style.background = "#ddd ";
            styleTemp.style.color = "#fff";
          }
        }
      }
    }
  }, [canNotReservation, startDate, endDate, current, reservationStartDate]);
  return (
    <InfoSlideDiv className="info-div reservation">
      <Modal state={imgModal} className="modal">
        <p
          onClick={() => setImgModal(false)}
          style={{
            top: "10%",
            color: "white",
            right: "29%",
            padding: 0,
            width: "fit-content",
            textAlign: "left",
          }}
        >
          X
        </p>
        <Slider {...settings} style={{ width: "50%", margin: "10% auto" }}>
          {imgSlideItems}
        </Slider>
      </Modal>
      <Modal state={reservationModal} className="modal">
        <div className="reservation-form">
          <p onClick={() => setReservationModal(false)}>X</p>
          <h1>예약하기</h1>
          <div>
            <div className="form-item">
              <label>캠핑장 이름 </label>
              <span>{infos.siteName}</span>
            </div>
            <div className="form-item">
              <label>예약날짜</label>
              <span>
                {startDate.getMonth() + 1}월{startDate.getDate()}일~
                {endDate.getMonth() + 1}월{endDate.getDate()}일({days}박)
              </span>
            </div>
            <div className="form-item">
              <label>예약자(입금자) * </label>
              <input
                onChange={(data) => {
                  setReservationInfos({
                    ...reservationInfos,
                    name: data.target.value,
                  });
                }}
              ></input>
            </div>
            <div className="form-item">
              <label>전화번호 * </label>
              <input
                onChange={(data) => {
                  setReservationInfos({
                    ...reservationInfos,
                    phone: data.target.value.replaceAll("-", ""),
                  });
                }}
              ></input>
            </div>
            <div className="form-item">
              <label>차량번호 * </label>
              <input
                onChange={(data) => {
                  setReservationInfos({
                    ...reservationInfos,
                    carNumber: data.target.value,
                  });
                }}
              ></input>
            </div>
            <div className="form-item">
              <label>인원수 * </label>
              <input
                onChange={(data) => {
                  setReservationInfos({
                    ...reservationInfos,
                    many: data.target.value,
                  });
                }}
              ></input>
            </div>
          </div>
          <div className="use-info">
            [이용약관 및 환불규정]
            <br />
            <br /> 캠핑장 이용 수칙을 지키지 않을 분들은 정중히 사양합니다.{" "}
            <br />
            <br />
            예약 건은 양도가 절대 불가합니다! 유의해주세요! <br />
            <br />
            1.예약은 1인 1사이트(최대 인원 4인) 예약 가능합니다.(동일인 2개
            사이트 예약 시 취소됨) <br />
            <br />
            2.입금자와 예약자가 일치해야 합니다. <br />
            <br />
            3.예약 확인 클릭하면 예약 접수가 되며, 예약 접수문자가 발송됩니다.
            입금은 반드시 예약 대기 문자(예약 접수 문자 아님)받으신 후
            입금바랍니다. 예약 접수 상태에서 입금 시 중복 예약으로 취소 될 수
            있습니다. <br />
            <br />
            4.예약 접수 및 승인 후 다음 날 오전 10시까지 미 입금 시 자동 취소
            됩니다. <br />
            <br />
            5.사용일 당일 및 전일 예약은 관리자 문의 후 진행되며 예약 후 취소 및
            환불은 불가합니다. <br />
            <br />
            6.입실 시간은 오후 2시, 퇴실 시간은 오전 11시입니다. <br />
            <br />
            7.매너타임 밤 10시 부터 아침 07시 까지입니다. <br />
            <br />
            8.입실 시 관리동에서 체크 인 후 입실 가능합니다. <br />
            <br />
            9.14시 이전의 입실은 불가합니다. 오후 8시 이후의 입실은 사전에
            반드시 연락 주시기 바랍니다. <br />
            <br />
            10.예약 인원 외 추가 인원 및 방문객 일절 불가합니다.(추가 인원 및
            방문객 입장 시 강제 퇴실 대상이며 환불되지 않습니다) <br />
            <br />
            11.반려동물 입장 불가합니다.(몰래 동반 시 환불 없이 퇴실 조치 함을
            양해 바랍니다) <br />
            <br />
            12.피칭 시 정해진 사이트 구역 내에만 피칭바랍니다. <br />
            <br />
            13.한 사이트 당 예약 차량 한대만 주차 가능합니다. <br />
            <br />
            14.개수대 사용 및 샤워는 23시 이후 이용 불가합니다. <br />
            <br />
            15.캠핑장 내에서는 절대 금연입니다.(캠핑장 외부 흡연 시 화재 및 산불
            예방을 위해 담배 꽁초를 버리지 말아 주세요) <br />
            <br />
            16.캠핑장 내에서 촛불과 폭죽 등 화재의 위험이 있는 행동은 절대
            금합니다. <br />
            <br />
            17.건조주의보 및 강풍주의보 시 장작불 및 숯불 등 화재 위험이 있는
            행위를 금지하며, 고객의 부주의로 산불 및 화재 피해 발생 시 민.형사상
            책임은 고객의 책임입니다. <br />
            <br />
            18.고성방가, 소란행위, 도박행위를 하시는 고객은 환불 없이 퇴실
            조치합니다. <br />
            <br />
            19.한 사이트 당 전기 사용 한도는 600W이며, 에어컨, 전열기 등의
            전압이 높은 전열기구 사용은 불가합니다. 반드시 KC인증받은 캠핑용품을
            사용해야 합니다. <br />
            <br />
            20.캠핑장 내부도로 및 외부도로 차량 이동이 빈번합니다. 아이를
            혼자두면 위험하며 문제 발생 시 캠핑장에서는 책임지지 않습니다.
            <br />
            <br />
            21.이용자의 부주의 인한 시설물의 파손과 분실의 경우에는 이용자께서
            변상해야 합니다. <br />
            <br />
            22.개인의 분실물은 책임지지 않습니다. <br />
            <br />
            23.야생 동물들이 음식물을 물어갈 수 있으니, 주의하시고 출.입 시
            방충망을 잘 닫아주세요. <br />
            <br />
            24.개수대에서 화로 세척을 절대 금합니다.(별도의 세척장을 이용해
            주세요) <br />
            <br />
            25.태풍, 집중 호우 등 긴급 상황 시 관리자의 지시에 따라 주십시요.
            <br />
            <br />
            26.동절기 캠핑 시 일산화탄소 경보기를 필수로 준비하시고 텐트 내
            환기구를 확보해주세요.(취침 시 화기 난방기기(난로 등)는 불을 끄고,
            텐트 밖으로 꺼내주세요. <br />
            <br />
            27.퇴실 시 일반 쓰레기는 종량제 비닐에 담아 배출하시고, 재활용품들은
            분리수거하여 주시기 바랍니다. <br />
            <br />
            28.퇴실 시 열쇠는 "꼭" 관리자에게 반납해 주셔야 합니다.
            <br />
            <br />
            29.열쇠 분실 시 30,000원의 변상금이 있습니다.
            <br />
            <br />
            문의전화 010-4081-9312(오후2시~6시까지)
            <br />
            <br />
            ☆환불규정☆
            <br />
            <br />※ 예약 건 양도 절대불가합니다. ※
            <br />
            <br /> 예약확정일 ~ 사용일 9일전(90%환불)-10%차감
            <br />
            <br />
            8일전(80%환불)-20%차감
            <br />
            <br />
            7일전(70%환불)-30%차감
            <br />
            <br />
            6일전(60%환불)-40%차감
            <br />
            <br />
            5일전(50%환불)-50%차감
            <br />
            <br />
            4일전(40%환불)-60%차감
            <br />
            <br />
            3일전(30%환불)-70%차감
            <br />
            <br />
            2일전(20%환불)-80%차감
            <br />
            <br />
            1일전( 0%환불)-100%차감 당일( 0%환불)-100%차감
            <br />
            <br />
            ※ 사용일 기준 9일 이전부터는 상품 변동이 불가합니다. ※
            <br />
            <br />
            문어발 식 예약 방지를 위해 취소 시 취소 수수료 10% 발생합니다.
            <br />
            <br />
            신중한 예약부탁드립니다{" "}
          </div>
          <div className="use-info-check">
            <input
              checked={userCheck}
              onChange={() => setUserCheck(!userCheck)}
              type="checkbox"
            ></input>
            <span>이용약관 및 환불규정을 읽고 모두 동의합니다</span>
          </div>

          <div className="button-wrap">
            <button onClick={() => setReservationModal(false)}>취소</button>
            <button
              onClick={() => {
                if (userCheck) {
                  if (
                    reservationInfos.carNumber == "" ||
                    reservationInfos.many == "" ||
                    reservationInfos.name == "" ||
                    reservationInfos.phone == ""
                  ) {
                    alert("예약 필수 요소를 채워주세요!");
                    return;
                  } else if (
                    startDate.getTime() <= reservationStartDate &&
                    endDate.getTime() <= reservationStartDate
                  ) {
                    postItem("/reservation/insert", {
                      ...reservationInfos,
                      siteName: infos.siteName,
                    });
                  } else {
                    alert("예약이 열리지 않은 예약 기간입니다!");
                    window.location.reload();
                  }
                } else {
                  alert("이용약관 및 환불 수칙에 동의해주세요!");
                }
              }}
            >
              예약하기
            </button>
          </div>
        </div>
      </Modal>

      <div className="reservation-flex" onClick={() => setImgModal(true)}>
        {imgItems}
      </div>
      <div className="reservation-content">
        <div className="left">
          <div className="title">{infos.siteName}</div>
          <div className="info">{subInfos}</div>
          <div className="info-content">
            <p>
              {infos.info.split("\n").map((data) => (
                <>
                  {data}
                  <br />
                </>
              ))}
            </p>
          </div>
          <div className="refund">
            <Link to="/customer">환불 규정 보러가기 {`>`}</Link>
          </div>
        </div>

        <Right
          style={
            idx == 0
              ? {
                  overflow: "hidden",
                  width: "0 !important",
                  height: "0 !important",
                  opacity: 0,
                  pointerEvent: "none",
                  position: "absolute",
                }
              : null
          }
          className="right"
        >
          <Calendar
            calendarType="US"
            prevLabel={
              <div
                onClick={() => {
                  setCurrent(Math.floor(Math.random() * 1000));
                }}
              >{`<`}</div>
            }
            nextLabel={
              <div
                onClick={() => {
                  setCurrent(Math.floor(Math.random() * 1000));
                }}
              >{`>`}</div>
            }
            onChange={setDate}
            value={value}
            formatDay={(locale, date) => formatDay(date, "d")}
          />
          <div>
            <div className="price">
              <span>총 합계</span>
              <p>
                <span> ₩{price.toLocaleString()}</span>
                <span>/{days}박</span>
              </p>
            </div>
            <button
              style={
                idx == 0
                  ? { opacity: 0, pointerEvent: "none", position: "absolute" }
                  : null
              }
              onClick={() => setReservationModal(true)}
            >
              예약하기
            </button>
          </div>
        </Right>
      </div>
    </InfoSlideDiv>
  );
};
const Right = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  & .react-calendar {
    width: 100%;
    height: 50%;
    margin: 0px auto;
    border: none;
    font-size: 21px;
  }
  & .react-calendar button {
    font-size: 21px;
    min-height: 3vw;
  }
`;
const Modal = styled.div`
  display: ${(props) => (props.state ? "block" : "none")};
  position: absolute;
  top: -70px;
  left: 0;
  z-index: 100000;
  & .slick-item div {
    margin: 0 5%;
    width: 100%;
    box-sizing: border-box;
  }
  & .slick-item div .img {
    margin: 0;
  }
  & p {
    text-align: right;
    font-size: 20px !important;
    padding: 0 7%;
    color: #b4c0d3;
    position: absolute;
    right: 32px;
    top: 30px;
    z-index: 100000;
  }
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: calc(100% + 70px);
`;
const InfoSlideDiv = styled.div`
  position: relative;
  & .slick-prev {
    background: url("${LeftButton}");
    left: 0px;
  }
  & .slick-next {
    background: url("${RightButton}");
    right: 0px;
  }
`;
export default Reservation;
