import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./style.css";
import styled from "styled-components";
import LeftButton from "../img/leftButton.png";
import RightButton from "../img/rightButton.png";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css"; // css import
import { useState } from "react";
import { useEffect } from "react";
import getList from "../../axios/getList";

const ReservationCalander = (props) => {
  const [value, onChange] = useState(
    new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate()
    )
  );
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [count, setCount] = useState(0);
  const idx = props.idx;
  const [reservationStartDate, setReservationStartDate] = useState(0);

  const [priceValueList, setPriceValueList] = useState([]);
  const [canNotReservation, setCanNotReservation] = useState([]);
  const [current, setCurrent] = useState(0);
  const formatDay = (locale) => {
    const dateData = new Date(locale);
    return dateData.getDate();
  };
  const setDate = (date) => {
    if (count % 2 == 0) {
      setStartDate(date);
    } else {
      setEndDate(date);
    }
    setCount(count + 1);
  };

  useEffect(() => {
    getList("/reservation/list?idx=" + idx).then((data) => {
      const listTemp = [];
      for (let i = 0; i < data.length; i++) {
        const distanceEnd =
          (new Date(data[i].endDate).getTime() -
            new Date(data[i].startDate).getTime()) /
          86400000;
        listTemp.push(new Date(data[i].startDate).getTime());

        for (let j = 1; j < distanceEnd; j++) {
          listTemp.push(new Date(data[i].startDate).getTime() + j * 86400000);
        }

        listTemp.push(new Date(data[i].endDate).getTime());
      }
      getList("/setting/setting-list?idx=" + idx + "&flag=5").then((data) => {
        for (let i = 0; i < data.length; i++) {
          const distanceEnd =
            new Date(
              data[i].dateList.split(",")[
                data[i].dateList.split(",").length - 1
              ] - new Date(data[i].startDate).getTime()
            ).getTime() / 86400000;
          listTemp.push(new Date(data[i].startDate).getTime());

          for (let j = 1; j <= Math.ceil(distanceEnd); j++) {
            listTemp.push(new Date(data[i].startDate).getTime() + j * 86400000);
          }
        }
        setCanNotReservation(listTemp);
      });
    });

    getList("/setting/setting-list?idx=" + idx + "&flag=3").then((data) => {
      const dataObj = {};
      for (let i = 0; i < data.length; i++) {
        dataObj[data[i].dateList] = parseInt(data[i].price);
      }
      setPriceValueList(dataObj);
    });
    getList("/setting/setting-list?idx=" + idx + "&flag=4").then((data) => {
      setReservationStartDate(new Date(data[0].startDate).getTime());
    });
  }, []);

  useEffect(() => {
    const calander = document.getElementById(idx + "-calander").children[0]
      .children[1].children[0].children[0].children[0].children[1];
    const childrenList = calander.children;

    setTimeout(() => {
      for (let i = 0; i <= childrenList.length; i++) {
        if (childrenList[i]) {
          const value = childrenList[i].children[0].innerHTML;
          const currentMonth = childrenList[i].children[0].ariaLabel
            .split(" ")[1]
            .split("월")[0];
          const canNotReservationStartMonthString = new Date(
            reservationStartDate
          )
            .toLocaleDateString()
            .split(". ")[1];

          for (let j = 0; j < canNotReservation.length; j++) {
            const canNotReservationMonthString = new Date(canNotReservation[j])
              .toLocaleDateString()
              .split(". ")[1];
            if (
              childrenList[i].children[0].ariaLabel.includes(
                canNotReservationMonthString + "월"
              )
            ) {
              if (
                value == new Date(canNotReservation[j]).getDate() &&
                new Date().getTime() <= new Date(canNotReservation[j]).getTime()
              ) {
                const styleTemp = childrenList[i];
                styleTemp.style.background = "#ddd ";
                styleTemp.style.color = "#fff";
              }
            }
          }
          if (
            current != 0 &&
            parseInt(currentMonth) >=
              parseInt(canNotReservationStartMonthString)
          ) {
            if (
              new Date(reservationStartDate).getTime() <=
              new Date(
                childrenList[i].children[0].ariaLabel
                  .replace("년 ", "-")
                  .replace("월 ", "-")
                  .replace("일", " 00:00:00")
              ).getTime()
            ) {
              const styleTemp = childrenList[i];
              styleTemp.style.background = "#ddd ";
              styleTemp.style.color = "#fff";
            }
          } else if (
            current != 0 &&
            parseInt(currentMonth) <=
              parseInt(canNotReservationStartMonthString)
          ) {
            if (
              new Date(reservationStartDate).getTime() <=
              new Date(
                childrenList[i].children[0].ariaLabel
                  .replace("년 ", "-")
                  .replace("월 ", "-")
                  .replace("일", " 00:00:00")
              ).getTime()
            ) {
              const styleTemp = childrenList[i];
              styleTemp.style.background = "#ddd ";
              styleTemp.style.color = "#fff";
            }
          }
        }
      }
    }, 10);
  }, [canNotReservation, current, reservationStartDate]);

  return (
    <InfoSlideDiv className="info-div reservation calander">
      <div className="reservation-content">
        <Right id={idx + "-calander"} className="right">
          <Calendar
            calendarType="US"
            prevLabel={
              <div
                onClick={() => {
                  setCurrent(Math.floor(Math.random() * 1000));
                }}
              >{`<`}</div>
            }
            nextLabel={
              <div
                onClick={() => {
                  setCurrent(Math.floor(Math.random() * 1000));
                }}
              >{`>`}</div>
            }
            onChange={setDate}
            value={value}
            formatDay={(locale, date) => formatDay(date, "d")}
          />
        </Right>
      </div>
    </InfoSlideDiv>
  );
};
const Right = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  & .react-calendar {
    width: 100%;
    height: 50%;
    margin: 0px auto;
    border: none;
    font-size: 21px;
  }
  & .react-calendar button {
    font-size: 21px;
    min-height: 3vw;
  }
`;
const Modal = styled.div`
  display: ${(props) => (props.state ? "block" : "none")};
  position: absolute;
  top: -70px;
  left: 0;
  z-index: 100000;
  & .slick-item div {
    margin: 0 5%;
    width: 100%;
    box-sizing: border-box;
  }
  & .slick-item div .img {
    margin: 0;
  }
  & p {
    text-align: right;
    font-size: 20px !important;
    padding: 0 7%;
    color: #b4c0d3;
    position: absolute;
    right: 32px;
    top: 30px;
    z-index: 100000;
  }
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: calc(100% + 70px);
`;
const InfoSlideDiv = styled.div`
  position: relative;
  & .slick-prev {
    background: url("${LeftButton}");
    left: 0px;
  }
  & .slick-next {
    background: url("${RightButton}");
    right: 0px;
  }
`;
export default ReservationCalander;
