import { useEffect, useState } from "react";
import "./style.css"
import getList from "../../axios/getList";
import styled from "styled-components";
import { Link } from "react-router-dom";
import ReservationCalander from "./reservationList";

const Img = styled.div`
background-image: url("${props => props.url}");
cursor: pointer;

`
const CampInfoReservation = () => {
    const [campList, setCampList] = useState([])
    useEffect(() => {
        getList("/camp/list").then(async data => {
            const list = []
            for (let i = 0; i < data.length; i++) {
                if (i % 3 == 0) {
                    list.push(
                        <div key={"info" + data[i].id} className="item">
                            <div>
                                <Img onClick={()=>{window.location.href="/reservation/" + data[i].id}} url={data[i].img1} className="img"></Img>
                                <h2><Link to={"/reservation/" + data[i].id}>{data[i].siteName}</Link>
                                    <span>{parseInt(data[i].startPrice).toLocaleString()}~{parseInt(data[i].endPrice).toLocaleString()}원</span></h2>
                                {i==0?null:<ReservationCalander idx={data[i].id}/>}

                            </div>
                            {data[i + 1] ?
                                <div>
                                    <Img onClick={()=>{window.location.href="/reservation/" + data[i+1].id}} url={data[i + 1].img1} className="img"></Img>
                                    <h2><Link to={"/reservation/" + data[i + 1].id}>{data[i + 1].siteName}</Link>
                                        <span>{parseInt(data[i + 1].startPrice).toLocaleString()}~{parseInt(data[i + 1].endPrice).toLocaleString()}원</span></h2>
                                        <ReservationCalander idx={data[i+1].id}/>
                                </div>

                                : <div>
                                </div>}
                            {data[i + 2] ?
                                <div>
                                    <Img onClick={()=>{window.location.href="/reservation/" + data[i+2].id}} url={data[i + 2].img1} className="img"></Img>
                                    <h2><Link to={"/reservation/" + data[i + 2].id}>{data[i + 2].siteName}</Link>
                                        <span>{parseInt(data[i + 2].startPrice).toLocaleString()}~{parseInt(data[i + 2].endPrice).toLocaleString()}원</span></h2>
                                        <ReservationCalander idx={data[i+2].id}/>
                                </div>

                                : <div>
                                </div>}

                        </div>
                    )
                }

            }
            setCampList(list)
        })
    }, [])
    return (
        <div className="info-div camp">
            <div className="info-title"> 캠핑장 예약현황</div>
            {campList}


        </div>
    )
}
export default CampInfoReservation;