import styled from "styled-components"
import react, { useContext, useEffect, useState } from "react"

const Content = (props) => {
    return (
        <STableWrapper style={{height : "fit-content"}}>
            <STable style={{height : "fit-content"}}>
                <thead>
                    <SRow height="50px;">
                    <SCell style={{textAlign:"left", padding : 20}}>No : {props.rowList.id}</SCell>
                    <SCell style={{textAlign:"left", padding : 20}}>작성 시간 : {new Date(props.rowList.date).toLocaleDateString().replaceAll(". ","-").replaceAll("."," ")+new Date(props.rowList.date).toLocaleTimeString()}</SCell>
                    <SCell style={{textAlign:"left", padding : 20}}>조회수 : {props.rowList.view}</SCell>
                    </SRow>
                    <SRow height="50px;">
                    <SCell colSpan={4} style={{textAlign:"left", padding : 20}}>제목 : {props.rowList.title}</SCell>

                    </SRow>
                </thead>
                <tbody>
                <SRow height="50px;">
                <SCell colSpan={4} style={{height : "30vh",textAlign:"left", padding : 20,lineHeight:"140%"}}>{props.rowList.content?(props.rowList.content).split('\n').map((data,idx)=><span key={new Date().getTime()+idx}>{data}<br/></span>):""}</SCell>
                </SRow>
                </tbody>

            </STable>
        </STableWrapper>
    );
}
const STableWrapper = styled.div`
    width : 100%;
    background : white;
    min-height : 10px;
    border-top :2px solid #333333;

    height : ${props => ((props.size) * 40) + 80}px;
    max-height : 60vh;
    -webkit-transition: height .3s, max-height .3s;
    transition: height .3s, max-height .3s;
    overflow:  scroll;
    &::-webkit-scrollbar {
        width: 4px;
        height : 0px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 12px;
        background:rgb(97, 137, 255);
        &:hover{
            background: blue;
        }
      }
      @media (max-width: 500px) {
        &{
            border-top :1px solid rgb(235, 238, 245);
            margin-bottom : 30px;
        }
      }
`
const SCell = styled.td`
vertical-align : middle;
padding : 5px 10px;
box-sizing : border-box;
width : fit-content;
text-align : center;
border-bottom : 1px solid #ebeef5;
font-size : 14px;
color : ${props => props.header ? "#8f8f8f;" : "black"};
font-weight : ${props => props.header ? "bold;" : "normal"};
white-space : nowrap;
width : ${props=>props.width};
`
const SRow = styled.tr`
    display : table-row; 
    height : ${props => props.height};
    box-sizing : border-box;
    background : ${props => props.flag == "0" ? "red" : null};
`
const STable = styled.table`
display : table;
    width : 100%;

`

export default Content
