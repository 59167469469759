import styled from "styled-components";
import "./style.css"
import getList from "../../axios/getList";
import { useEffect, useState } from "react";
const InfoMain = () => {
    const [mainImgList, setMainImgList] = useState([])
    const [mainList, setMainList] = useState([])

    useEffect(() => {
        getList("/main-img/main").then(data => {
            const list = []
            for (let i = 0; i < data.length; i++) {
                list.push(
                    <div key={data[i].id+"main"} className="info-title">
                        
                            <h3>{data[i].subTitle}</h3>
                            <h1>{data[i].title}</h1>
                            <p>{data[i].info.split("\n").map(data=><>{data}<br/></>)}
                            </p>
                    </div>
            )
            }
            setMainList(list)
        })
        getList("/main-img/list").then(data => {
            const list = []
            for (let i = 0; i < data.length; i++) {
                list.push(
                    <div className={"info-item "+(i%2==0?"left":"right")} key={"slide" + i}>
                        <Img url={data[i].imgUrl} className="img"></Img>
                        <div className="content">
                            <h3>{data[i].subTitle}</h3>
                            <h1>{data[i].title}</h1>
                            <p>{data[i].info.split("\n").map(data=><>{data}<br/></>)}
                            </p>
                        </div>
                    </div>
                )
            }
            setMainImgList(list)
        })
    }, [])

    return (
        <div className="info-div main">
            {mainList}
            {mainImgList}
        </div>
    )
}
const Img = styled.div`
background-image: url("${props=>props.url}");
`
export default InfoMain;