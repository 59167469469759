import "./main/style.css";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import * as React from "react";
import ham from "./img/ham.png";
const AdminHeader = () => {
  useEffect(() => {
    if (window.innerWidth < 500) {
      hamButton();
    }
  }, []);
  const hamButton = () => {
    const element = document.getElementById("mo-menu");
    if (element.style.display == "none") {
      element.style.display = "flex";
    } else {
      element.style.display = "none";
    }
  };
  return (
    <>
      <div className="header admin">
        <div id="header-logo">
          <Link to="/admin">두랭이해변오토캠핑장 어드민 페이지</Link>
        </div>
        <ul id="mo-menu">
          <li>
            <Link to="/reservation-check-admin">예약 목록</Link>
          </li>
          <li>
            <Link to="/reservation-setting-admin">예약 관련 설정</Link>
          </li>
          <li>
            <Link to="/notice-admin">공지사항 설정</Link>
          </li>
          <li>
            <Link to="/popup-admin">추가 팝업 설정</Link>
          </li>
          <li>
            <Link to="/slide-admin">슬라이드 설정</Link>
          </li>
          <li>
            <Link to="/use-admin">이용 안내 설정</Link>
          </li>

          <li>
            <Link to="/main-admin">메인 설정</Link>
          </li>
          <li>
            <Link to="/camp-admin">캠프장 설정</Link>
          </li>
        </ul>
        <div id="ham-button" onClick={() => hamButton()}>
          <img src={ham} />
        </div>
      </div>
    </>
  );
};
export default AdminHeader;
