import axios from "axios";

const getList = (url, page, view) => {
  const pageParam = page != null ? "?page=" + page + "&view=" + view : null;
  const resUrl = pageParam ? url + pageParam : url;
  return axios
    .get("http://doorangicamping.com:8080/admin" + resUrl)
    .then((data) => {
      return data.data;
    });
};
export default getList;
